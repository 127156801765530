import React from 'react'
import Sidebar from "../global-components/Sidebar";
import Header from "../global-components/Header";
import TermsBanner from '../../assets/img/terms-banner.png';
function TermsAndPrivacy() {
    return (
        <> 
          <div className='main-div'>
            <div className="container-fluid pt-5">
                <div className="row">
                    <Sidebar />
                    <div className="main-content container col-lg-9">
                        <div className="container">
                            <Header title={'Terms & Privacy'} />
                            <hr className='hr w-100' />
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="terms-banner">
                                        <h2>Terms of Use</h2>
                                        <p>By utilizing the services provided by Visualize.AI ("we," "us," or "our"), you are subject to the following terms and conditions ("Terms"). Your use of our website or any of our services signifies your agreement to adhere to these Terms</p>
                                    </div>
                                </div>
                                <div className="col-md-5 py-3">
                                    <div>
                                        <img className="terms-banner-icon"  src={TermsBanner} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="terms-detail">
                                        <h3>Service Usage</h3>
                                        <p>Our services are available for personal or commercial use. We specialize in transforming sketches and wireframes into renders tailored for architects, interior designers, and product designers. Your use of our services must be lawful and in compliance with these Terms.</p>
                                        <h3>User Registration</h3>
                                        <p>To access our services, registration for an account is required. You must provide accurate and complete information during the registration process. Safeguarding the security of your account and maintaining confidentiality of your login details are your responsibilities. Notify us immediately if you suspect any unauthorized access to your account.</p>
                                        <h3>Fees and Payment</h3>
                                        <p>We offer both free and paid plans. The free plan permits the visualization of ideas, though all ideas created under this plan will be public. Upgrading to the paid plan ensures privacy for your ideas. Fees for our services may change at our discretion, and any modifications will be communicated by updating our website.</p>
                                        <h3>Intellectual Property</h3>
                                        <p>We uphold the intellectual property rights of all parties. While you retain ownership of sketches, wireframes, or renders created using our services, you grant us a non-exclusive, royalty-free, worldwide license to utilize, modify, distribute, and display your ideas to provide our services. Using our services to infringe upon others' intellectual property rights or to upload illegal, harmful, or offensive content is prohibited.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </>
    )
}

export default TermsAndPrivacy