import React, { useEffect, useState } from "react";
import ForgotLogo from "../../assets/img/verify-email.png";
import Logo from "../../assets/img/logo-png.png";
import { Link, useParams, useNavigate, useSearchParams } from "react-router-dom";
import { checkNumber } from '../../GlobalFunction'
import { otpValidation, resendOtp } from '../../services'
import Email from '../../assets/img/Email.png'
import Component4 from '../../assets/img/component4.png'
import LeftArrow from '../../assets/img/leftarrow.svg'

const otpField = [
  { id: 1, placeholder: 3, },
  { id: 2, placeholder: 5 },
  { id: 3, placeholder: 7 },
  { id: 4, placeholder: 7 },

]

const VerifyEmail = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const { token } = useParams()
  const [otp, setOtp] = useState({})
  const [email, setEmail] = useState()
  const [errorLog, setErrorLog] = useState({})
  const [loader, setLoader] = useState(false)
  const [otpResendLoader, setOptResendLoader] = useState(false)
  const [resendBtnName, setResendBtnName] = useState('Click to Resend')

  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
    setEmail(searchParams.get("email"))
  }, [])

  const otpHandlr = (e, index) => {
    delete errorLog['non_field_errors']
    setErrorLog(errorLog)
    setErrorLog({})
    const { value } = e.target
    let isValid = checkNumber(value)
    if (isValid === '') {
      let otp_ = { ...otp, [index]: value }
      setOtp(otp_)
    } else {
      let errorLog_ = { ...errorLog, otp: [isValid] }
      setErrorLog(errorLog_)
    }

  }

  const verfiyHandler = async () => {
    let finalOtp = []
    if (Object.keys(otp).length > 0) {
      Object.keys(otp).map((item, index) => {
        let otp_ = otp[item]
        if (otp_) {
          finalOtp.push(otp_)
        }
      })
    } else {
      let errorLog_ = { ...errorLog, otp: ['Otp is required'] }
      setErrorLog(errorLog_)
    }


    if (finalOtp.length > 4 || finalOtp.length < 4) {
      let errorLog_ = { ...errorLog, otp: ['Otp must be 4 digit'] }
      setErrorLog(errorLog_)
    } else {
      finalOtp = finalOtp.toString()
      finalOtp = finalOtp.replaceAll(',', '')
      if (finalOtp.length === 4) {
        setLoader(true)
        let responseData = await otpValidation({ token, otp: finalOtp })
        const { code, data } = responseData
        if (code === 200) {
          const { token } = data
          navigate(`/create-password/${token}`)
        } else {
          setErrorLog(data)
        }
        setLoader(false)
      } else {
        let errorLog_ = { ...errorLog, otp: ['Otp must be 4 digit'] }
        setErrorLog(errorLog_)
      }
    }

  }

  const resendOtpHandler = async () => {
    delete errorLog['non_field_errors']
    setErrorLog(errorLog)
    setErrorLog({})
    setOptResendLoader(true)
    let responseData_ = await resendOtp(token)
    const { code } = responseData_
    if (code === 200) {
      setOptResendLoader(false)
      setResendBtnName('Sucessfully sent')
    }
    setOptResendLoader(false)
    setTimeout(() => {
      setResendBtnName('Click to Resend')
    }, 4000)
  }

  return (
    <>
      <div className="forgot-password-steps d-flex align-items-center justify-content-center">
        <div className="container-fluid row px-0">
          <div className="col-md-8 col-sm-12 p2container1  d-flex align-items-center justify-content-center">
            <div className="p2container2">
              <img className="p2container3" src={Component4} alt="" />
              <h4 className='p2text2'>Forgot your Password? <br />
                NO worries!</h4>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 Reg-right-main">
            <div className="Reg-right d-flex align-items-center justify-content-center">
              <div className="Form_reg_div" style={{ width: '80%'}}>
                <h4 className='text-center'>Password Reset</h4>
                <p className='text-center' style={{fontSize:'14px'}}>We sent code to {email}{" "}</p>

                <div className="Reg_input_div mt-2 ">
                  <div className="d-flex align-items-center justify-content-between">
                    {
                      otpField.map((item, index) => {
                        return (
                          <input
                            key={index}
                            type="text"
                            max={1}
                            min={1}
                            className="form-control shadow-sm text-center mx-2"
                            id="exampleFormControlInput1"
                            style={{ padding: '0'}}
                            placeholder={item.placeholder}
                            onChange={(e) => { otpHandlr(e, index) }}
                          />
                        )
                      })
                    }
                  </div>

                  {errorLog.otp &&
                    <div className="text-center text-danger">{errorLog.otp}</div>
                  }
                  {errorLog.non_field_errors &&
                    <div className="text-center text-danger">{errorLog.non_field_errors}</div>
                  }
                  <div className="d-flex justify-content-center">
                    <button className='Reg_form_input_btn d-flex align-items-center justify-content-center mt-3' onClick={() => { verfiyHandler() }}>
                      {loader ?
                        <div className="spinner-border text-light" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        :
                        'Continue'
                      }
                    </button>
                  </div>
                  <div className="text-center mt-3"> Didn't receive the email?   
                  {otpResendLoader ?
                      <div className="px-1">
                        <div className="spinner-grow spinner-grow-sm px-2" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-grow spinner-grow-sm" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-grow spinner-grow-sm" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-grow spinner-grow-sm" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                      :
                      resendBtnName === 'Click to Resend' ?
                        
                        <span className="text-primary px-1" onClick={() => resendOtpHandler()}>  {resendBtnName}</span>
                        :
                        <span className="text-primary px-1">  {resendBtnName }</span>
                    }
                  </div>

                  <div className="text-center mt-3">
                <Link to={'/login'}>
                <button>
                  <img className="px-2" src={LeftArrow} alt="" />
                  Back to Login 
                </button>
                </Link>
              </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="forgot-password-steps d-flex align-items-center justify-content-center">
        <div
          className="text-center forgot-password-card shadow-sm  p-5"
          style={{ width: "480px" }}
        >
          <div className="text-center">
            <Link to='/'>
              <img src={Logo} width={150} className="mb-4 logo" />
            </Link>
            <p className="text-secondary fs-sm">
              Browse through a diverse range of interior styles, from contemporary
              and minimalist to rustic nd electic. Our curted selection will
              inspire you to envision your space in a whole new light.
            </p>
          </div>
          <div className="mt-4">
            <img src={ForgotLogo} width={50} className="mb-2" />
            <div className="mb-3 px-5">
              <h5 className="mb-3">Verify Email</h5>
              <label
                htmlFor="exampleFormControlInput1"
                className="mb-3 fs-sm fw-5"
              >
                Please Enter The 4 Digit Code Sent To <br /> {email}{" "}
              </label>
              <div className="d-flex align-items-center justify-content-between">
                {
                  otpField.map((item, index) => {
                    return (
                      <input
                        key={index}
                        type="text"
                        max={1}
                        min={1}
                        className="form-control shadow-sm text-center mx-2"
                        id="exampleFormControlInput1"
                        placeholder={item.placeholder}
                        onChange={(e) => { otpHandlr(e, index) }}
                      />
                    )
                  })
                }
              </div>
              {errorLog.otp &&
                <div className="text-start text-danger">{errorLog.otp}</div>
              }
              {errorLog.non_field_errors &&
                <div className="text-start text-danger">{errorLog.non_field_errors}</div>
              }
              <div
                className="text-decoration-none d-block fw-5 text-primary mt-3"
                style={{ cursor: "pointer" }}
              >
                {otpResendLoader ?
                  <>
                    <div className="spinner-grow spinner-grow-sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow spinner-grow-sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow spinner-grow-sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow spinner-grow-sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </>
                  :
                  resendBtnName === 'Resend code' ?
                    <span onClick={() => resendOtpHandler()}>{resendBtnName}</span>
                    :
                    <span >{resendBtnName}</span>
                }
              </div>
              <div className="btn btn-primary forgetbtn fw-5 mt-3 py-md" onClick={() => { verfiyHandler() }}>
                {loader ?
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  :
                  'Verify'
                }
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default VerifyEmail;
