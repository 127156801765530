let ENV = process.env.REACT_APP_MODE;
let DOMAIN = 'https://api.homeshopper.ai'
if(ENV == "LOCAL") {
    DOMAIN = "http://127.0.0.1:8000";
}
const API_VERSION_V1    = '/api/v1'
const API_VERSION_V2    = '/api/v2'

const APIs = {
    DOMAIN : DOMAIN,
    SIGNUP_API:DOMAIN+API_VERSION_V1+'/signup/',
    SOCIAL_AUTH:DOMAIN+API_VERSION_V1+'/social-auth/',
    LOGIN_API:DOMAIN+API_VERSION_V1+'/login/',
    ACCESS_TOKEN:DOMAIN+API_VERSION_V1+'/refresh/',
    EDIT_PROFILE:DOMAIN+API_VERSION_V1+'/edit-profile/',
    FORGOT_PASSWORD:DOMAIN+API_VERSION_V1+'/forgot-auth/',
    OTP_VALIDATION:DOMAIN+API_VERSION_V1+'/token-validate/',
    UPDATE_PASSWORD:DOMAIN+API_VERSION_V1+'/update-auth/',
    CONTACT_US:DOMAIN+API_VERSION_V1+'/contact/',
    GET_NOTIFICATIONS:DOMAIN+API_VERSION_V1+'/notifications/',
    GENERATE_ALTERNATIVE_IMAGE:DOMAIN+API_VERSION_V2+'/generate-alternative/',
    GET_TYPES:DOMAIN+API_VERSION_V2+'/types/',
    GENERATE_IMAGE:DOMAIN+API_VERSION_V2+'/generate/',
    GENERATE_PRODUCT:DOMAIN+API_VERSION_V2+'/product/',
    SAVE_INFO:DOMAIN+API_VERSION_V2+'/save-info/',
    GET_HISTORY:DOMAIN+API_VERSION_V2+'/history/',
    SEARCH:DOMAIN+API_VERSION_V2+'/search/',
    RELATED_TEMPLATE:DOMAIN+API_VERSION_V2+'/template/',
    UPLOAD_INIT_IMAGE:DOMAIN+API_VERSION_V2+'/upload-initImage/',
}

export default APIs;