import React from 'react'

const ImageCard = ({image,checked,showIcon=true}) => {
  return (
    <div className='p-0'>
        <div className={'shadow-sm bg-white room-type-card'+ (checked?' active_room':'')}>
            <div className='position-relative'>
                <div className='position-relative'>
                    <img src={image}/>
                    {checked && showIcon && 
                      <i className='material-icons fs-1 position-absolute'>check_circle</i>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default ImageCard