import React, { useState, useEffect } from "react";
import ForgotLogo from "../../assets/img/new-pass.png";
import Logo from "../../assets/img/logo-png.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { updatePassword } from '../../services'
import Component4 from '../../assets/img/component4.png'
import Lock from '../../assets/img/Lock.png'
import LeftArrow from '../../assets/img/leftarrow.svg'

const CreatePassword = () => {
  const navigate = useNavigate()
  const { token } = useParams()
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorLog, setErrorLog] = useState({})
  const [loader, setLoader] = useState(false)
  const [successMsg, setSuccessMsg] = useState(false)

  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  }, [])

  const updatePasswordHandler = (e, type) => {
    delete errorLog['non_field_errors']
    setErrorLog(errorLog)
    setErrorLog({})
    const { value } = e.target
    let errorLog_ = {}
    if (newPassword.length < 8) {
      errorLog_ = { ...errorLog, newpassword: ['New password must be 8 character'] }
      setErrorLog(errorLog_)
    }
    if (type === 'new') {
      setNewPassword(value)
    } else {
      setConfirmPassword(value)
      if (newPassword !== value) {
        errorLog_ = { ...errorLog, password: ['Confirm password not matched'] }
        setErrorLog(errorLog_)
      }
    }
  }

  const savePasswordHandler = async () => {
    setErrorLog({})
    if (newPassword === '' && confirmPassword === '') {
      let errorLog_ = {
        ...errorLog,
        newpassword: ['New password is required'],
        confirmpassword: ['Confirm password is required']
      }
      setErrorLog(errorLog_)
    } else if (newPassword === '') {
      let errorLog_ = {
        ...errorLog,
        newpassword: ['New password is required'],
      }
      setErrorLog(errorLog_)
    } else if (confirmPassword === '') {
      let errorLog_ = {
        ...errorLog,
        confirmpassword: ['Confirm password is required']
      }
      setErrorLog(errorLog_)
    } else if (newPassword.length < 8) {
      let errorLog_ = { ...errorLog, newPassword: ['New password must be 8 character'] }
      setErrorLog(errorLog_)
    }

    if (newPassword !== '' && confirmPassword !== '' && Object.keys(errorLog).length === 0) {
      setLoader(true)
      let responseData = await updatePassword({ token, password: confirmPassword })
      const { code, data } = responseData
      if (code === 200) {
        setSuccessMsg('Your password is successfully updated, Please wait redirect to login page')
        setTimeout(() => {
          navigate(`/login`)
        }, 4000)
      } else if (code === 400) {
        setErrorLog(data)
      }
      setLoader(false)
    }
  }

  return (
    <><div className="forgot-password-steps d-flex align-items-center justify-content-center">
      <div className="container-fluid row px-0">
        <div className="col-md-8 col-sm-12 p2container1  d-flex align-items-center justify-content-center">
          <div className="p2container2">
            <img className="p2container3" src={Component4} alt="" />
            <h4 className='p2text2'>Forgot your Password? <br />
              NO worries!</h4>
          </div>
        </div>

        <div className="col-md-4 col-sm-12 Reg-right-main">
          <div className="Reg-right d-flex align-items-center justify-content-center">
            <div className="Form_reg_div">
              <h4 className='text-center'>Set new Password</h4>
              <p className='text-center'>Must be at least 8 Characters</p>

              <div className="Reg_input_div mt-2">
                <div className='d-flex align-items-center justify-content-center'>
                  <input
                    value={newPassword}
                    type="password"
                    className="Reg_form_input"
                    id="exampleFormControlInput1"
                    placeholder="************"
                    onChange={(e) => { updatePasswordHandler(e, 'new') }}
                  />
                  <img className='Reg_form_input_icon' src={Lock} alt="" />
                </div>
              </div>
              
                
              {errorLog.newpassword &&
                    <div className="text-start text-danger">{errorLog.newpassword}</div>
                  }
              <div className="Reg_input_div d-flex  align-items-center justify-content-center mt-2">
                <input
                  value={confirmPassword}
                  type="password"
                  className="Reg_form_input"
                  id="exampleFormControlInput2"
                  placeholder="************"
                  onChange={(e) => { updatePasswordHandler(e, 'confirm') }}
                />
                <img className='Reg_form_input_icon' src={Lock} alt="" />
              </div>
              {errorLog.password &&
                <div className="text-start text-danger">{errorLog.password}</div>
              }
              <div className="d-flex align-items-center justify-content-center">
                <button className='Reg_form_input_btn d-flex  align-items-center justify-content-center mt-3' onClick={() => { savePasswordHandler() }}>
                  {loader ?
                    <div className="spinner-border text-light" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    :
                    'Reset Password'
                  }
                </button>
              </div>
              
              <div className="text-center mt-3">
                <Link to={'/login'}>
                <button>
                  <img className="px-2" src={LeftArrow} alt="" />
                  Back to Login 
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      {/* <div className="forgot-password-steps d-flex align-items-center justify-content-center">
        <div
          className="text-center forgot-password-card shadow-sm  p-5"
          style={{ width: "480px" }}
        >
          <div className="text-center">
            <Link to='/'>
              <img src={Logo} width={150} className="mb-4 logo" />
            </Link>
            <p className="text-secondary fs-sm">
              Browse through a diverse range of interior styles, from contemporary
              and minimalist to rustic nd electic. Our curted selection will
              inspire you to envision your space in a whole new light.
            </p>
          </div>
          <div className="mt-4">
            <img src={ForgotLogo} width={50} className="mb-2" />
            <div className="mb-3 px-5">
              <h5 className="mb-2">Create New Password</h5>
              <label className="form-label fs-sm fw-5">
                Your New Password Must Be Diffrent from Your Previously Used
                Password.
              </label>
              <div className="mb-3 text-start">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                  New Password
                </label>
                <input
                  value={newPassword}
                  type="password"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="************"
                  onChange={(e) => { updatePasswordHandler(e, 'new') }}
                />
                {errorLog.newpassword &&
                  <div className="text-start text-danger">{errorLog.newpassword}</div>
                }
              </div>
              <div className="mb-2 text-start">
                <label htmlFor="exampleFormControlInput2" className="form-label">
                  Confirm Password
                </label>
                <input
                  value={confirmPassword}
                  type="password"
                  className="form-control"
                  id="exampleFormControlInput2"
                  placeholder="************"
                  onChange={(e) => { updatePasswordHandler(e, 'confirm') }}
                />
                {errorLog.confirmpassword &&
                  <div className="text-start text-danger">{errorLog.confirmpassword}</div>
                }
              </div>
              {errorLog.password &&
                <div className="text-start text-danger">{errorLog.password}</div>
              }
              {errorLog.non_field_errors &&
                <div className="text-start text-danger">{errorLog.non_field_errors}</div>
              }
              {successMsg}
              <div className="btn btn-primary forgetbtn fw-5 mt-3 py-md" onClick={() => { savePasswordHandler() }}>
                {loader ?
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  :
                  'Save'
                }
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default CreatePassword;
