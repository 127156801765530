import React from 'react'
import Sidebar from "../global-components/Sidebar";
import Header from "../global-components/Header";
import SearchIcon from '../../assets/img/search-icon.png';
function Faq() {
    return (
        <>
        <div className="main-div">
            <div className="container-fluid pt-5">
                <div className="row">
                    <Sidebar />
                    <div className="main-content container col-lg-9">
                        <div className="container">
                            <Header title={'FAQ’s'} />
                            <hr className='hr w-100' />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container px-1 px-md-5">
                                        <div className="text-center">
                                            <h2>Frequently Ask Questions</h2>
                                            <p>Interior design involves planning and creating functional spaces, while interior decoration focuses on furnishing and adding aesthetic elements to those spaces.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="container px-2 px-md-5">
                                        <div className="faq-detail">
                                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                                <div className="accordion-item mt-4">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        <img src={SearchIcon} alt="" className='pe-2'/>What is home interior design?
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">Home interior design is the art and science of enhancing the interior of
                                                            a residential space to create a more aesthetically pleasing and functional environment.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mt-4">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        <img src={SearchIcon} alt="" className='pe-2'/>Why should I consider hiring a professional interior designer?
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">Professional interior designers have the expertise to transform your space by considering your preferences, lifestyle, and maximizing functionality while maintaining a cohesive design concept.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mt-4">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                        <img src={SearchIcon} alt="" className='pe-2'/>How do I choose the right interior designer for my project?
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">Look for designers with a style that resonates with your vision, check their portfolio, read client reviews, and have a consultation to ensure a good fit.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item my-4">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseThree">
                                                        <img src={SearchIcon} alt="" className='pe-2'/>What is the difference between interior design and interior decoration?
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">Interior design involves planning and creating functional spaces, while interior decoration focuses on furnishing and adding aesthetic elements to those spaces.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Faq