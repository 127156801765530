import React,{useEffect} from 'react';
import {getLocalStorageData,getAccessToken} from '../services';
import { useNavigate } from 'react-router-dom';

export default function ProtectedRoute({children}) {
    const  navigate = useNavigate()

    useEffect(()=>{
        let token_ = getLocalStorageData('token_','simple')
        if (token_ == '' || token_ == undefined){
            navigate('/login')
        } 
    },[])

    useEffect(()=>{
        getAccessToken()
    },[navigate])
    
    return children
}
