import React, { useState, useEffect } from 'react'
import Logo from '../../assets/img/logo.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { clearLocalStorageData, searchData } from '../../services'
import UserIcon from '../../assets/img/user-icon.png';
import ContactIcon from '../../assets/img/contact-icon.png';
import FaqIcon from '../../assets/img/faq-icon.png';
import HistoryIcon from '../../assets/img/history-icon.png';
import HomeIcon from '../../assets/img/home-icon.png';
import TermIcon from '../../assets/img/term-icon.png';
import NotificationIcon from '../../assets/img/notification-icon.png';
import LogoutIcon from '../../assets/img/logout-icon.svg';
import Dummy from '../../assets/img/dummy-profile-img.png';
import HamburgerIcon from '../../assets/img/hamburger-icon.svg';
import { getLocalStorageData } from '../../services'

function Sidebar() {
    let [name, setName] = useState('')
    const [profileUpdated, setProfileUpdated] = useState(false); // State variable to track event

    useEffect(() => {
      let userInfo = getLocalStorageData('info', 'object');
      const { first_name } = userInfo;
      setName(first_name);

      const handleProfileUpdated = () => {
        setProfileUpdated(true);
      };
      window.addEventListener('profileUpdated', handleProfileUpdated);
  
      return () => {
        window.removeEventListener('profileUpdated', handleProfileUpdated);
      };
    }, []);
  
    useEffect(() => {
      if (profileUpdated) {
        let userInfo = getLocalStorageData('info', 'object');
        const { first_name } = userInfo;
        setName(first_name);
        setProfileUpdated(false);
      }
    }, [profileUpdated]);

    const [isOpen, setIsOpen] = useState('closed');
    const toggleSidebar = () => {
        if (isOpen === 'closed') {
            setIsOpen('open');
        } else if (isOpen === 'open') {
            setIsOpen('closing');
            setTimeout(() => {
                setIsOpen('closed');
            }, 900);
        }
    };

    const navigate = useNavigate()

    const [activeLink, setActiveLink] = useState('/');
    const location = useLocation();

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location]);

    const logOutHandler = () => {
        clearLocalStorageData()
        setTimeout(() => {
            navigate('/login')
        }, 900)
    }

    const navigateHome = () => {
        navigate('/home')
    }

    return (
        <>
            <div className="col-lg-3 col-md-3">
                <button onClick={toggleSidebar} className="toggle-sidebar">
                    <img src={HamburgerIcon} alt="Toggle Sidebar" />
                </button>

                <div className={`sidebar-background ${isOpen}`}>
                    <div className={`sidebar-main ${isOpen}`}>
                        <div className='logo text-center' onClick={() => { navigateHome() }} type="button">
                            <img src={Logo} width='100%' height='100%' alt="" />
                        </div>
                        <div className="sidebar-profile d-flex flex-column justify-content-center align-items-center mt-4" >
                            <Link to={'/profile'}> <button className='my-4'> <img src={Dummy} alt="" /> </button> </Link>
                            <h3 className='fw-bold text-center'>{name}</h3>
                            <h6 className='fw-normal text-secondary text-center'>Credits: 3</h6>
                        </div>
                        <div className="sidebar-links">
                            <div className='sidebar-link'>
                                <ul className='d-flex flex-column justify-content-between'>
                                    <li className={activeLink === '/home' ? 'active-link' : ''}><Link to={'/home'}><img src={HomeIcon} alt="" /> Home</Link> </li>
                                    <li className={activeLink === '/profile' ? 'active-link' : ''}><Link to={'/profile'}><img src={UserIcon} alt="" /> Profile</Link></li>
                                    <li className={activeLink === '/notifications' ? 'active-link' : ''}><Link to={'/notifications'}><img src={NotificationIcon} alt="" />Notifications</Link></li>
                                    <li className={activeLink === '/history' ? 'active-link' : ''}><Link to={'/history'}><img src={HistoryIcon} alt="" />History</Link></li>
                                    <li className={activeLink === '/faqs' ? 'active-link' : ''}><Link to={'/faqs'}><img src={FaqIcon} alt="" />Faq's</Link></li>
                                    <li className={activeLink === '/contact' ? 'active-link' : ''}><Link to={'/contact'}><img src={ContactIcon} alt="" />Contact Us</Link></li>
                                    <li className={activeLink === '/terms-and-privacy' ? 'active-link' : ''}><Link to={'/terms-and-privacy'}><img src={TermIcon} alt="" />Terms of Use</Link></li>
                                    <li className='sidebar-logout-item'>
                                        <div className="sidebar-logout">
                                            <Link onClick={() => { logOutHandler() }}>
                                                <button> <img src={LogoutIcon} alt="" /> Signout </button>
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar