import React,{useState} from 'react'
import LoginBack from '../../assets/img/login-back.jpg';
import Logo from '../../assets/img/logo-png.png';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {userLogin,setLocalStorageData} from '../../services'
import {checkValidEmail} from '../../GlobalFunction'
import SocialAuth from '../SocialAuth'
import Email from '../../assets/img/Email.png'
import Lock from '../../assets/img/Lock.png'
import Component4 from '../../assets/img/component4.png'

const Login = () => {
    const navigate = useNavigate()
    const [email,setEmail]                  = useState('')
    const [password,setPassword]            = useState('')
    const [errorLog,setErrorLog]            = useState({})
    const [loader,setLoader]                = useState(false)


    const handler = (e,type) =>{
        setErrorLog({})
        const {value} = e.target
        let errorLog_ = {}
        switch (type) {
            case 'email':
                setEmail(value)
                let emailError = checkValidEmail(value)
                errorLog_ = {...errorLog,email:[emailError]}
                setErrorLog(errorLog_)
                break;
            case 'password':
                setPassword(value)
                break;
            default:
                break;
        }
    }

    const  loginHandler = async() => {
        setErrorLog({})
        if (email === '' && password === ''  ){
            let errorLog_ = {
                ...errorLog,
                email:['Email is required'],
                password:['Password is required']
            }
            setErrorLog(errorLog_)
        }  else if (email === '') {
            let errorLog_ = {...errorLog,email:['Email is required']}
            setErrorLog(errorLog_)
        } else if (password === '') {
            let errorLog_ = {...errorLog,password:['Password is required']}
            setErrorLog(errorLog_)
        } 

        if (email !== ''  && password !== ''){
            setLoader(true)
            let  responseData = await userLogin({email,password})
            const {code,data} = responseData
            if (code === 200){
                const {refresh,access,info} = data
                setLocalStorageData('token_',refresh,'token')
                setLocalStorageData('token',access,'token')
                setLocalStorageData('info',info)
                navigate('/home')

            } else if (code===400) {
                setErrorLog(data)
            }
            setLoader(false)
        }
    }

    const loaderHandler_ = (status)=> {
        setLoader(status)
    }
   
    return (
        <div className='Register_main_cont'>
            <div className='container-fluid'>
                <div className="row Register_main_row">
                    <div className="col-md-8 col-sm-12 p2container1  d-flex align-items-center justify-content-center">
                        <div className="p2container2">
                            <img className="p2container3" src={Component4} alt="" />
                            <h4 className='p2text2'>Get Started <br />
                            Home Shopper UI</h4>
                        </div>
                    </div>
                    
                    <div className="col-md-4 col-sm-12 Reg-right-main">
                        <div className="Reg-right d-flex align-items-center justify-content-center">
                            <div className="Form_reg_div">
                                <h4 className='text-center'>Ready to Go</h4>
                                <p className='text-center'>Welcome  Back!</p>
                                    <div className="d-flex  align-items-center justify-content-center">
                                        <SocialAuth loaderHandler={(status)=>loaderHandler_(status)}/>
                                    </div>
                                    
                                    <div className="Reg_input_div mt-2">
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <input className='Reg_form_input' onChange={(e)=>{handler(e,'email')}} type="Email" placeholder='Email Address' />
                                            <img className='Reg_form_input_icon' src={Email} alt="" />
                                        </div>
                                    </div>
                                    { errorLog.email && 
                                        <div className="text-start text-danger">{errorLog.email}</div>
                                    }
                                    <div className="Reg_input_div d-flex  align-items-center justify-content-center mt-2">
                                        <input className='Reg_form_input' onChange={(e)=>{handler(e,'password')}} type="password" placeholder='Password' />
                                        <img className='Reg_form_input_icon' src={Lock} alt="" />
                                    </div>
                                    { errorLog.password && 
                                            <div className="text-start text-danger">{errorLog.password}</div>
                                        }
                                    <button className='Reg_form_input_btn d-flex  align-items-center justify-content-center mt-3' onClick={()=>{loginHandler()}}>
                                        {   loader ?
                                            <div className="spinner-border text-light" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        :
                                            'Login Now'
                                        }
                                    </button>
                                <div className='mt-4 login_last_text text-center'>
                                    <h6>First time here? 
                                        <Link to={'/register'} className='text-decoration-none text-primary mx-2'>Sign Up</Link>
                                        Or <br />
                                        <Link to={'/forgot-password'} className='text-decoration-none text-primary'>Recover Your Account</Link>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='col-md-4 p-3'>
                        <div className='mt-3 d-flex align-items-center justify-content-center main-login-form' style={{height: '94vh'}}>
                            <div className='text-center login-form' style={{width: '350px'}}>
                                <div className='ps-3 pt-5 text-center'>
                                    <img src={Logo} width={180} className='mb-5 logo'/>
                                </div>
                                <h3 className='p-0 m-0 font-family'>Welcome back! 👋</h3>
                                <div className="mb-3 mt-4">
                                    <input type="email" onChange={(e)=>{handler(e,'email')}}  value={email} className="form-control bg-light borderLess-input" id="exampleFormControlInput1" placeholder="Email address" />
                                    { errorLog.email && 
                                        <div className="text-start text-danger">{errorLog.email}</div>
                                    }
                                </div>
                                <div className="mb-3">
                                    <input type="password" onChange={(e)=>{handler(e,'password')}} value={password} className="form-control bg-light borderLess-input" id="exampleFormControlInput1" placeholder="Password" />
                                    { errorLog.password && 
                                        <div className="text-start text-danger">{errorLog.password}</div>
                                    }
                                </div>
                                { errorLog.non_field_errors && 
                                    <div className="text-start text-danger">{errorLog.non_field_errors}</div>
                                }
                                <div  className='btn btn-primary authbtn fw-5 mt-3 py-md w-100' onClick={()=>{loginHandler()}}>
                                    { loader ?
                                        <div className="spinner-border text-light" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    :
                                        'Sign in'
                                    }
                                </div>
                                <div className='d-flex align-items-center justify-content-center flex-wrap mt-5 mb-3'>
                                    <SocialAuth loaderHandler={(status)=>loaderHandler_(status)}/>
                                </div>
                                <div className='mt-3'>
                                    <h6>first time here? 
                                        <Link to={'/register'} className='text-decoration-none text-primary mx-2'>Sign Up</Link>
                                        Or <br />
                                        <Link to={'/forgot-password'} className='text-decoration-none text-primary'>ForgotPassword</Link>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>            
        </div>
    )
}

export default Login