import validator from 'validator';

export const checkValidEmail = (email) => {
    try{
        let emailError = "";
        if (!validator.isEmail(email) && email.length > 0) {
            emailError = "Please enter valid email address";
        }
        return emailError;
    } catch(error) {
    }
}

export const checkPhoneNumber = (number) => {
    try{
        let numberError = "";
        if (!validator.isMobilePhone(number) && number.length > 0) {
            numberError = "Invalid phone number";
        }
        return numberError;
    } catch(error) {
    }
}

export const checkNumber = (number) => {
    try{
        let numberError = "";
        if (!validator.isNumeric(number) && number.length == 1) {
            numberError = "Otp is must be Digit";
        }
        return numberError;
    } catch(error) {
    }
}


export const debounce = (func, delay) => {
    let timeoutId;
    return function() {
      const context = this;
      const args = arguments;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };
