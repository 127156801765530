import React, { createContext, useContext, useState } from 'react';
const QueryContext = createContext();

export const QueryProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState(null);

  const updateQuery = newQuery => {
    setSearchQuery(newQuery);
  };

  return (
    <QueryContext.Provider value={{ searchQuery, updateQuery }}>
      {children}
    </QueryContext.Provider>
  );
};

export const useQuery = () => {
  const context = useContext(QueryContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
