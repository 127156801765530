import React, { useState } from 'react'
import LoginBack from '../../assets/img/login-back2.jpg';
import Logo from '../../assets/img/logo-png.png';
import { Link } from 'react-router-dom';
import { checkValidEmail, checkPhoneNumber } from '../../GlobalFunction'
import { userRegistrationHandler, setLocalStorageData } from '../../services'
import { useNavigate } from 'react-router-dom';
import { countryCode } from '../../assets/CountryCodes'
import SocialAuth from '../SocialAuth'
import Component3 from '../../assets/img/component3.png'
import UserIcon from '../../assets/img/UserIcon.png'
import Email from '../../assets/img/Email.png'
import Lock from '../../assets/img/Lock.png'


const Register = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')
    const [errorLog, setErrorLog] = useState({})
    const [loader, setLoader] = useState(false)
    const [locale, setLocale] = useState('fa-AF')
    const [countryCode_, setCountryCode] = useState('+93')

    const handler = (e, type) => {
        delete errorLog['password']
        let errorLog_ = {}
        setErrorLog(errorLog)
        setErrorLog({})
        const { value } = e.target
        switch (type) {
            case 'email':
                setEmail(value)
                let emailError = checkValidEmail(value)
                errorLog_ = { ...errorLog, email: [emailError] }
                setErrorLog(errorLog_)
                break;
            case 'phone':
                setPhoneNumber(value)
                let phoneNumberError = checkPhoneNumber(value, locale)
                errorLog_ = { ...errorLog, phone_number: [phoneNumberError] }
                setErrorLog(errorLog_)
                break;
            case 'password':
                setPassword(value)
                if (value.length < 8) {
                    errorLog_ = { ...errorLog, password: ['Password must be 8 character'] }
                    setErrorLog(errorLog_)
                }
                break;
            default:
                setErrorLog({})
                break;
        }
    }

    const localeHandler = (e) => {
        const { value } = e.target
        let filterCountry = countryCode.filter((data) => { return data.dial_code === value })
        if (filterCountry.length > 0) {
            setLocale(filterCountry[0].locale)
            setCountryCode(filterCountry[0].dial_code)
        }
    }

    const registrationHandler = async () => {
        setErrorLog({})
        if (email === '' && phoneNumber === '' && password === '') {
            let errorLog_ = {
                ...errorLog,
                email: ['Email is required'],
                phone_number: ['Phone number is required'],
                password: ['Password is required']
            }
            setErrorLog(errorLog_)
        } else if (email === '') {
            let errorLog_ = { ...errorLog, email: ['Email address is required'] }
            setErrorLog(errorLog_)
        } else if (phoneNumber === '') {
            let errorLog_ = { ...errorLog, phone_number: ['Phone number is required'] }
            setErrorLog(errorLog_)
        } else if (password === '') {
            let errorLog_ = { ...errorLog, password: ['Password is required'] }
            setErrorLog(errorLog_)
        } else if (password.length < 8) {
            let errorLog_ = { ...errorLog, password: ['Password must be 8 character'] }
            setErrorLog(errorLog_)
        }

        if (email !== '' && phoneNumber !== '' && password !== '' && Object.keys(errorLog).length === 0) {
            setLoader(true)
            let phoneNumber_ = `${countryCode_}${phoneNumber}`
            let responseData = await userRegistrationHandler({ email, phone_number: phoneNumber_, password, country_code: countryCode_ })
            const { code, data } = responseData
            if (code === 201) {
                const { refresh, access, info } = data
                setLocalStorageData('token_', refresh, 'token')
                setLocalStorageData('token', access, 'token')
                setLocalStorageData('info', info)
                navigate('/')

            } else if (code === 400) {
                setErrorLog(data)
            }
            setLoader(false)
        }
    }

    const loaderHandler_ = (status) => {
        setLoader(status)
    }

    return (
        <>
            <div className="Register_main_cont">
                <div className="container-fluid ">
                    <div className="row Register_main_row">
                        <div className="col-md-8 col-sm-12 p2container1  d-flex align-items-center justify-content-center">
                            <div className="p2container2">
                                <img className="p2container3" src={Component3} alt="" />
                                <h4 className='p2text1'>Digital Platform For <br />
                                    Creating Interior Design</h4>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 Reg-right-main">
                            <div className="Reg-right d-flex align-items-center justify-content-center">
                                <div className="Form_reg_div">
                                    <h4 className='text-center'>Get Started</h4>
                                    <p className='text-center'>Sign Up to Get Started</p>
                                    <div className="Reg_input_div mt-3">
                                        <input className='Reg_form_input' type="text" placeholder='Full Name' />
                                        <img className='Reg_form_input_icon' src={UserIcon} alt="" />
                                    </div>
                                    <div className="mt-2 input-group Reg_form_input" style={{ padding:'8px' }}>
                                        <select id="countryCode" onChange={(e)=>localeHandler(e)}  className="form-select" style={{ border:'none' }}>
                                            {
                                                countryCode.map((item,index)=>{
                                                    return(
                                                        <option key={index} value={item.dial_code}>
                                                            {item.code} ({item.dial_code})
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <input type="text" value={phoneNumber} onChange={(e)=>{handler(e,'phone')}} className="form-control" style={{ border:'none' }} id="exampleFormControlInput1" placeholder="Phone number" />
                                    </div>
                                    { errorLog.phone_number && 
                                        <div className="text-start text-danger">{errorLog.phone_number}</div>
                                    }
                                    <div className="Reg_input_div mt-2">
                                        <input className='Reg_form_input' type="email" value={email} onChange={(e) => { handler(e, 'email') }} placeholder='Email Address' />
                                        <img className='Reg_form_input_icon' src={Email} alt="" />
                                    </div>
                                    {errorLog.email &&
                                        <div className="text-start text-danger">{errorLog.email}</div>
                                    }
                                    <div className="Reg_input_div mt-2">
                                        <input className='Reg_form_input' type="password" value={password} onChange={(e) => { handler(e, 'password') }} placeholder='Password' />
                                        <img className='Reg_form_input_icon' src={Lock} alt="" />
                                    </div>
                                    {errorLog.password &&
                                        <div className="text-start text-danger">{errorLog.password}</div>
                                    }
                                    <button className='Reg_form_input_btn mt-3' onClick={() => { registrationHandler() }}>
                                        {loader ?
                                            <div className="spinner-border text-light" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            :
                                            'Register'
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register