import React from 'react'
import {saveData} from '../../../services'

export default function Product({data,processId}) {

    const redirectHandler = async(path) =>{
        try {
            window.open(path,'_blank');
            const formData = new FormData();
            formData.append('url', path); 
            formData.append('process_id', processId); 
            await saveData(formData);
        } catch (error) {
            //console.log(error);
        }
        
    }

  return (
    <div className={'shadow-sm m-2 bg-white position-relative p-3 justify-content-center room-type-card productInfo'}>
        {/* <div className='position-relative productImage  d-flex justify-content-center col-12'>
            <div className='position-relative'>
                <img src={data.thumbnail}/>
            </div>
        </div> */}
        {/* <div className='itemInfo ps-3 p-3 productContent justify-content-start col-12'>
            <div className='producTitle'>
                {data.title}
            </div>
        </div> */}
        {/* <div className='itemInfo ps-3 p-3 d-flex justify-content-start col-12'>
            {   data.price ?
                <div className='productPrice'>
                    {data.price.currency}{data.price.extracted_value}
                </div>
                :
                <div className='productPrice deactivate'>
                    Click to Go to the seller and see price
                </div>
            }
        </div> */}
        <div className="itemInfo justify-content-start col-12">
            <div 
                className="btn btn-primary text-center fw-5" 
                onClick={()=>{redirectHandler(data)}}
                style={{width: "100%"}}
            >
                Go to the seller
            </div>
        </div>
    </div>
  )
}
