import React, { useEffect, useState } from "react";
import Sidebar from "../global-components/Sidebar";
import Header from "../global-components/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../global-components/Footer";
import { getNotifications } from '../../services'
import CuNotification from "../global-components/CuNotification";

const Notification = () => {
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    getNotificationsData()
  }, [])

  const getNotificationsData = async () => {
    setLoader(true)
    let responseData = await getNotifications()
    const { code, data } = responseData
    if (code === 200) {
      setData(data)
      setLoader(false)
    } else {
      setLoader(false)
    }
  }


  return (
    <>
      <div className="main-div">
        <div className="container-fluid pt-5">
          <div className="row ">
            {/* Main SideBar */}
            <Sidebar />
            {/* Main Content */}
            <div className="main-content container col-lg-9">
              <div className="container">
                <div className="">
                  <Header
                    title="Notifications"
                    subtitle="Showing all updates related Home-Interior with AI"
                  />
                  <hr className='hr w-100' />
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="">
                      <div className="">
                        <h4>Showing all updates related Home-Interior with AI</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"home-page-content" + (loader ? " disabled notifiy" : " activateNotifiy") + (data.length == 0 ? " deactivate_notifiy" : "")}>
                  {/* Drag and Drop section */}

                  {!loader ?
                    data.length > 0 ?
                      <CuNotification data={data} />
                      :
                      <h5 className="text-center">Not Found</h5>
                    :
                    <div className="spinner-border text-dark" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  }


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
