import React,{useEffect,useState} from "react";
import Sidebar from "../../global-components/Sidebar";
import Header from "../../global-components/Header";
import Product from "./Product";

const ProductComp = ({backHandler_,data,processId}) => {
    const [imageData,setImageData]  = useState([])

    const  backToStep5Handler = () =>{
        backHandler_()
    }

    useEffect(()=>{
        if (data){
            setImageData(data)
        }
    },[])

    return (
        <div className="main-div">
            <div className="container-fluid pt-5">
                <div className="row">
                    <Sidebar />
                    <div className="main-content col-lg-9">
                        <div className="container">
                            <Header title={'Hasham Naeem'} />
                            <hr className='hr w-100' />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="">
                                        <h4>Click to Buy an item now</h4>
                                    </div>
                                    {/* <div className="my-3 d-flex">
                                        <div onClick={()=>{backToStep5Handler()}} className="fw-5 fs-5 p-0 m-0 text-decoration-none text-dark d-flex align-items-center pointer_cursor"
                                        >
                                        <i className="material-icons fs-5">arrow_back_ios</i>Go Back
                                        </div>
                                    </div> */}
                                    <div className="croperContainer">
                                        <div className="w-100  flex-wrap d-flex justify-content-around">  
                                            { 
                                                imageData.map((item,index)=>{
                                                return(
                                                    <div key={index} className="pointer_cursor col-12 col-xl-3 col-md-3">
                                                        <Product data={item} processId={processId}  />
                                                    </div>
                                                )  
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductComp;
