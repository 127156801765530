import React, { useEffect, useState } from "react";
import Sidebar from "../global-components/Sidebar";
import Header from "../global-components/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomPrevArrow from "../sections-components/CustomPrevArrow";
import CustomNextArrow from "../sections-components/CustomNextArrow";
import { updateProfile, getLocalStorageData, setLocalStorageData } from '../../services'
import { checkValidEmail, checkPhoneNumber } from '../../GlobalFunction'
import { countryCode } from '../../assets/CountryCodes'
import ProjectHistory from "../global-components/ProjectHistory";
import Banner from '../../assets/img/profile-banner.png'
import DummyProfile from '../../assets/img/dummy-profile-img.png'
import EditIcon from '../../assets/img/edit-profile-icon.png'
import { Link } from 'react-router-dom'

const Profile = () => {
  let [name, setName] = useState('')
  let [information, setInformatione] = useState('')
  let [email, setEmail] = useState('')
  let [location, setLocation] = useState('')
  let [phoneNumber, setPhoneNumber] = useState('')
  const [errorLog, setErrorLog] = useState({})
  const [loader, setLoader] = useState(false)
  const [locale, setLocale] = useState('en-IN')
  let [countryCode_, setCountryCode] = useState('')

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  useEffect(() => {
    let userInfo = getLocalStorageData('info', 'object')
    const { first_name, information, email, location, phone_number, country_code } = userInfo
    setName(first_name)
    setInformatione(information)
    setEmail(email)
    setLocation(location)
    let phoneNumber = phone_number
    if (country_code) {
      phoneNumber = phone_number.split(country_code)[1]
      setCountryCode(country_code)
    }
    setPhoneNumber(phoneNumber)
  }, [])


  const handler = (e, type) => {
    errorLog[type] = ''
    setErrorLog(errorLog)
    const { value } = e.target
    let errorLog_ = {}
    switch (type) {
      case 'name':
        setName(value)
        if (value === '') {
          errorLog_ = { ...errorLog, name: ['Name is required.'] }
          setErrorLog(errorLog_)
        }
        break;
      case 'email':
        setEmail(value)
        let emailError = checkValidEmail(value)
        errorLog_ = { ...errorLog, email: [emailError] }
        setErrorLog(errorLog_)
        if (value === '') {
          errorLog_ = { ...errorLog, email: ['Email address is required.'] }
          setErrorLog(errorLog_)
        }
        break;
      case 'phone_number':
        setPhoneNumber(value)
        let phoneNumberError = checkPhoneNumber(value)
        errorLog_ = { ...errorLog, phone_number: [phoneNumberError] }
        setErrorLog(errorLog_)
        if (value === '') {
          errorLog_ = { ...errorLog, phone_number: ['Phone number is required.'] }
          setErrorLog(errorLog_)
        }
        break;
      case 'information':
        setInformatione(value)
        if (value === '') {
          errorLog_ = { ...errorLog, information: ['Information is required.'] }
          setErrorLog(errorLog_)
        }
        break;
      case 'location':
        setLocation(value)
        if (value === '') {
          errorLog_ = { ...errorLog, location: ['Location is required.'] }
          setErrorLog(errorLog_)
        }
        break;
      default:
        break;
    }
  }

  const updateProfileHandler = async () => {
    setLoader(true)
    let phoneNumber_ = `${countryCode_}${phoneNumber}`

    location = location === null ? '' : location
    email = email === null ? '' : email
    name = name === null ? '' : name
    information = information === null ? '' : information
    countryCode_ = countryCode_ === null ? '' : countryCode_
    phoneNumber_ = phoneNumber_ === 'null' ? '' : phoneNumber_

    let data_ = { email, first_name: name, phone_number: phoneNumber_, location, information, country_code: countryCode_ }
    let responseData = await updateProfile(data_)
    const { code, data } = responseData
    if (code === 200) {
      setLocalStorageData('info', data.info, 'object')
      window.dispatchEvent(new CustomEvent('profileUpdated'));
      var myModal = document.getElementById("exampleModal");
      myModal.classList.remove("show");
      var myModal_ = document.getElementsByClassName("modal-backdrop")[0];
      myModal_.classList.remove("show");
      setLoader(false)
    } else {
      setLoader(false)
      if (data.first_name) {
        data.name = data.first_name
      }
      setErrorLog(data)
    }
    setLoader(false)
  }


  const localeHandler = (e) => {
    const { value } = e.target
    let filterCountry = countryCode.filter((data) => { return data.dial_code === value })
    if (filterCountry.length > 0) {
      setLocale(filterCountry[0].locale)
      setCountryCode(filterCountry[0].dial_code)
    }
  }

  return (
    <>
      <div className="main-div">
        <div className="container-fluid pt-5">
          <div className="row ">
            {/* Main SideBar */}
            <Sidebar />
            {/* Main Content */}
            <div className="main-content container col-lg-9 m-0">
              <div className="container">
                <Header title={'Profile'} />
                <hr className='hr w-100' />
                <div>
                    <img width={'100%'} src={Banner} alt="" />
                    <div className="row">
                        <div className="col-md-2">
                            <div className='profile-main'>
                                <div className='profile-img'>
                                    <img src={DummyProfile} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 d-flex justify-content-between mt-3 mt-md-0">
                            <div className='profile-name align-content-center mx-4'>
                                <h4> {name} <span></span></h4>
                                <p> {email} </p>
                            </div>
                            <div className="profile-action d-flex flex-wrap justify-content-end">
                                <div className="edit-profile mb-3">
                                    <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"><img src={EditIcon} alt="" />Edit Profile</button>
                                </div>
                                <div className="upgrade-acc">
                                    <Link>Upgrade Account</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 className='mt-3'>Personal Information</h4>
                <div className="row m-0">
                    <div className="col-md-4">
                        <div className="personal-info">
                            <input type="text" name="" placeholder={'Full Name:  ' + name} className="bg-white" disabled/>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="personal-info">
                            <input type="text" name="" placeholder={'Mobile:  ' + phoneNumber} className="bg-white" disabled/>
                        </div>
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-md-4">
                        <div className="personal-info">
                            <input type="text" name="" placeholder={'Email:  '+ email} className="bg-white" disabled/>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="personal-info">
                            <input type="text" name="" placeholder={'Location:  ' + location} className="bg-white" disabled/>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="">
                  {/* edite Profile modal/ */}
                  <div
                    className="modal fade show"
                    id="exampleModal"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content px-5 profile-update-modal">
                        <div className="modal-header text-center">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Update Information
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className='update-body'>
                            <form>
                              <div className="mb-2">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="d-block mt-3"
                                >
                                  Full Name
                                </label>
                                <input value={name} onChange={(e) => { handler(e, 'name') }} type="text" className="w-100 mt-3 mx-0" />
                                {errorLog.name &&
                                  <div className="text-start text-danger">{errorLog.name}</div>
                                }
                              </div>
                              <div className="mb-2">
                                <label
                                  htmlFor="exampleInputPassword1"
                                  className="d-block mt-3 mb-3"
                                >
                                  Mobile
                                </label>
                                <div className="mb-2 input-group">
                                  <select id="countryCode" onChange={(e) => localeHandler(e)} className="form-select" style={{ border:'none' }}>
                                    {
                                      countryCode.map((item, index) => {
                                        return (
                                          <option selected={(item.dial_code === countryCode_ ?
                                            true : false)} key={index} value={item.dial_code}>
                                            {item.code} ({item.dial_code})
                                          </option>
                                        )
                                      })
                                    }
                                  </select>
                                  <input value={phoneNumber} onChange={(e) => { handler(e, 'phone_number') }} type="text" className="mt-0 mx-0" style={{ width:'70%' }} />
                                </div>
                                {errorLog.phone_number &&
                                  <div className="text-start text-danger">{errorLog.phone_number}</div>
                                }
                              </div>
                              <div className="mb-2">
                                <label
                                  htmlFor="exampleInputPassword1"
                                  className="d-block mt-3"
                                >
                                  Email
                                </label>
                                <input value={email} type="email" onChange={(e) => { handler(e, 'email') }} className="w-100 mt-3 mx-0" />
                                {errorLog.email &&
                                  <div className="text-start text-danger">{errorLog.email}</div>
                                }
                              </div>
                              <div className="mb-2">
                                <label
                                  htmlFor="exampleInputPassword1"
                                  className="d-block mt-3"
                                >
                                  Location
                                </label>
                                <input value={location} type="text" onChange={(e) => { handler(e, 'location') }} className="w-100 mt-3 mx-0" />
                                {errorLog.location &&
                                  <div className="text-start text-danger">{errorLog.location}</div>
                                }
                              </div>

                              <div className="mb-2">
                                <label
                                  htmlFor="exampleInputPassword1"
                                  className="d-block mt-3"
                                >
                                  Personal Information
                                </label>
                                <input value={information} type="textarea" onChange={(e) => { handler(e, 'information') }} className="w-100 mt-3 mx-0" />
                                {errorLog.information &&
                                  <div className="text-start text-danger">{errorLog.information}</div>
                                }
                              </div>

                            </form>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button onClick={() => !loader ? updateProfileHandler() : () => { }} type="button" className="btn profileBtn btn-primary">
                            {loader ?
                              <div className="spinner-border text-light" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div>
                              :
                              'Save changes'
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Suggested Card Slider */}
                  <div className="row mt-5">
                    <div className="mb-3">
                      <h5 className="p-0 m-0">Recent projects</h5>
                      <h6 className="text-secondary pt-1 m-0">
                        Architect design houses
                      </h6>
                    </div>
                    <ProjectHistory ishow={false} type={"all"} isSearch={false} searchData={[]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default Profile;
