import React, { useState, useEffect } from "react";
import Sidebar from "../global-components/Sidebar";
import Header from "../global-components/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getTypes } from '../../services'
import { generateAlternativeImage, uploadInitImage } from '../../services'
import BoundBox from './steps/BoundBox'
import RoomStyle1 from '../../assets/img/room-style-1.png'
import RoomStyle2 from '../../assets/img/room-style-2.png'
import RoomStyle3 from '../../assets/img/room-style-3.png'
import RoomStyle4 from '../../assets/img/room-style-4.png'
import RoomStyle5 from '../../assets/img/room-style-5.png'
import RoomStyle6 from '../../assets/img/room-style-6.png'
import checkMarkImage from '../../assets/img/icons8-checkmark.svg'

const Upload = () => {
  const [image, setImage] = useState(false)
  const [showStep1, setShowStep1] = useState(false)

  const uploadImageHandler = async (e) => {
    const file = e.target.files[0];
    setImage(file)
    setShowStep1(true)
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      setImageSrc(e.target.result)
    };
  }

  const backHandler = () => {
    setImage(false)
    console.log("saf");
    setShowStep2(false)
  }

  const [imageSrc, setImageSrc] = useState()
  const [step1Data, setStep1Data] = useState({})
  const [showStep2, setShowStep2] = useState(false)
  const [loader, setLoader] = useState(false)
  const [roomType, setRoomType] = useState('choose')
  const [styleType, setStyleType] = useState('choose')
  const [prompt, setPrompt] = useState('')
  const [prompt2, setPrompt2] = useState('')
  const [errorLog, setErrorLog] = useState({})
  const [apiError, setApiError] = useState(false)
  const [processId, setProcessId] = useState('')
  const [btnLoader, setBtnLoader] = useState(false)
  const [data, setData] = useState([])
  const [showBoundBox, setShowBoundBox] = useState(false)


  useEffect(() => {
    getRoomStyleTypes()
  }, [])

  const getRoomStyleTypes = async () => {
    setLoader(true)
    let responseData = await getTypes('room')
    const { data, code } = responseData
    if (code === 200) {
      setStep1Data(data)
      setLoader(true)
    }
    setLoader(false)
  }

  const styleHandler = (style) => {
    setErrorLog({});
    setStyleType(style);
  };

  const chooseHandler = (e, type) => {
    setErrorLog({})
    const { value } = e.target
    if (type === 'room') {
      setRoomType(value)
    } else if (type === 'style') {
      setStyleType(value)
    } else if (type === 'prompt') {
      setPrompt(value)
    }
    else if (type === 'prompt2') {
      setPrompt2(value)
    }
  }

  const processHandler = () => {
    setErrorLog({})
    if (!image) {
      setErrorLog({ prompt: "Image is required" })
    }
    if (roomType !== 'choose' && styleType === 'choose' && prompt === '') {
      setErrorLog({ style: "Please choose the style", prompt: "Prompt is required" })
    }
    if (styleType !== 'choose' && roomType === 'choose' && prompt === '') {
      setErrorLog({ room: "Please choose the room", prompt: "Prompt is required" })
    }
    if (styleType === 'choose' && roomType === 'choose' && prompt !== '') {
      setErrorLog({ room: "Please choose the room", style: "Please choose the style" })
    }
    if (styleType !== 'choose' && roomType === 'choose' && prompt !== '') {
      setErrorLog({ room: "Please choose the room" })
    }
    if (styleType === 'choose' && roomType !== 'choose' && prompt !== '') {
      setErrorLog({ style: "Please choose the style" })
    }
    if (roomType !== 'choose' && styleType !== 'choose' && prompt === '') {
      setErrorLog({ prompt: "Prompt is required" })
    }
    if (roomType !== 'choose' && styleType !== 'choose' && prompt2 === '') {
      setErrorLog({ prompt2: "Prompt is required" })
    }
    if (roomType !== 'choose' && styleType !== 'choose' && prompt !== '' && image) {
      generateAlternativeImage_()
    }

    if (roomType === 'choose' && styleType === 'choose' && prompt === '' && image) {
      uploadInitImage_()
    }
  }

  const generateAlternativeImage_ = async () => {
    setBtnLoader(true)
    let file = image
    let prompt_ = `${roomType.replace(' Room', '')}, ${styleType} style, ${prompt}`
    let formData = new FormData();
    formData.append("image", file, file.name);
    formData.append("prompt", prompt_);
    formData.append("room_type", roomType);
    formData.append("style_type", styleType);
    try {
      let responseData = await generateAlternativeImage(formData);
      const { code, data, message } = responseData
      if (code === 200) {
        const { result, process_id } = data
        let finalData = []
        result.map((image, index) => {
          finalData.push({ id: index + 1, src: image })
          return true
        })
        setData(finalData)
        setShowStep2(true)
        setProcessId(process_id)
        setBtnLoader(false)
      } else {
        setApiError(true)
        setBtnLoader(false)
        setShowStep2(false)
        setData([])
        setErrorLog(true)
      }
    } catch (error) {
      setApiError(true)
      setBtnLoader(false)
      setData([])
      setShowStep2(false)
      setErrorLog(true)
    }
  }

  const uploadInitImage_ = async () => {
    setBtnLoader(true)
    let file = image
    let formData = new FormData();
    formData.append("image", file, file.name);
    formData.append('id', false)
    try {
      let responseData = await uploadInitImage(formData);
      const { code, data, message } = responseData
      if (code === 200) {
        const { process_id } = data
        setShowStep2(true)
        setProcessId(process_id)
        setBtnLoader(false)
        setShowBoundBox(true)
      } else {
        setBtnLoader(false)
        setShowStep2(false)
      }
    } catch (error) {
      setBtnLoader(false)
      setShowStep2(false)
    }
  }

  const backtoHome = () => {
    backHandler()
  }
  // const handleImageClear = () => {
  //   setImageSrc(null);
  // }
  return (

    <>
      {!showStep2 ?
        <div className="main-div">
          <div className="container-fluid pt-5">
            <div className="row ">
              <Sidebar />
              <div className="main-content container col-lg-9">
                <div className="container">
                  <Header
                    title="Home"
                    subtitle="Upload image Or generate new Home-Interior with AI"
                  />
                  <hr className='hr w-100' />
                  <div className="row">
                    <div className="col-md-12">
                      <div className="">
                        <div className="">
                          <h4>Modify your interior design by harnessing the power of AI</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">

                    {/* Drag and Drop section */}
                    <div className="col-md-12 mb-4 mb-md-0" style={{ minHeight: '50vh' }}>
                      <input type="file"
                        onChange={(e) => { uploadImageHandler(e) }}
                        id="imageInput" style={{ display: "none" }}
                        accept="image/*"
                      />
                      <label htmlFor="imageInput" id="roomImageUploader" className="w-100 h-100">
                        <div className="dragdrop p-3 shadow-sm">
                          {imageSrc ? (
                            <div>
                              {/* <button onClick={handleImageClear}>Clear</button> */}
                              <img src={imageSrc} height={'100%'} width={'100%'} alt="Uploaded Preview" className="img-fluid" />
                            </div>
                          ) : (
                            <div className="h-100 d-flex align-items-center justify-content-center">
                              <i className="material-icons">cloud_upload</i>
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                    <div>
                    </div>
                  </div>
                  {errorLog.prompt &&
                    <div className="text-start text-danger mb-2 mt-1">
                      {errorLog.prompt}
                    </div>
                  }
                  <div class="col-12 mt-3">
                    {apiError &&
                      <div className="text-center text-danger mb-2 mt-1">
                        Something went wrong please try again later.
                      </div>
                    }
                    <div onClick={() => processHandler()} style={{ width: "100%" }} type="button" class="pick-submit">
                      {btnLoader ?
                        <button>
                          <div className="spinner-border text-light" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </button>
                        :
                        <button>
                          Submit
                        </button>
                      }
                    </div>
                  </div>


                  <div id="container" className="">
                    <img id="selectedImage" src="#" alt="Selected Image" className="w-100 step_3-img shadow-sm border"
                      style={{ display: "none" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <>
          {
            <BoundBox backHandler_={backHandler} processId={processId} data={imageSrc} initState={true} />
          }
        </>
      }
    </>
  );
};

export default Upload;
