import React from "react";
import Sidebar from "../global-components/Sidebar";
import Header from "../global-components/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../global-components/Footer";
import SupportCard from "../sections-components/SupportCard";
import ContactForm from "../sections-components/ContactForm";
import ContactImg from "../../assets/img/contactUs.png";
import CallIcon from '../../assets/img/call-icon.png'
import SupportIcon from '../../assets/img/support-icon.png'
import MapIcon from '../../assets/img/map-icon.png'

const Contact = () => {
  return (
    <>
      <div className="main-div">
        <div className="container-fluid pt-5">
          <div className="row ">
            {/* Main SideBar */}
            <Sidebar />
            {/* Main Content */}
            <div className="main-content container col-lg-9" >
              <div className="container">
                <Header title={'Contact Us'} />
                <hr className='hr w-100' />
                <div className="row">
                  <div className="col-md-12">
                    <div className="container">
                      <div className="contact-us text-center mt-4">
                        <h3>Get in touch with us</h3>
                        <p className='mb-4'>Get in touch with our friendly support team, and we will be happy to assist you as soon as we can</p>
                        <div className=" mt-5">
                          <div className="row d-flex justify-content-around">
                            <div className="col-md-4 px-2">
                              <div className="contact-infos px-md-2 px-lg-4">
                                <div className="contact-icon">
                                  <img width={'39px'} height={'29px'} src={SupportIcon} alt="" />
                                </div>
                                <h4>24/7 Support</h4>
                                <p>hello@homeshopper.com</p>
                              </div>
                            </div>
                            <div className="col-md-4 mt-md-4 px-md-2 px-lg-4">
                              <div className="contact-infos px-4">
                                <div className="contact-icon">
                                  <img width={'39px'} height={'29px'} src={CallIcon} alt="" />
                                </div>
                                <h4>Call Us Now</h4>
                                <p>+1 (587) 703-7239</p>
                              </div>
                            </div>
                            <div className="col-md-4 px-md-2 px-lg-4">
                              <div className="contact-infos px-4">
                                <div className="contact-icon">
                                  <img width={'39px'} height={'29px'} src={MapIcon} alt="" />
                                </div>
                                <h4>Visit Us At</h4>
                                <p>9655 49 Ave Nw Edmonton,<br /> AB, Canada, T6E 5Z5</p>
                              </div>
                            </div>

                            <div className="col-md-12">
                                <ContactForm />
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
            );
};

            export default Contact;
