import React, { useEffect, useState, useRef } from "react";
import StepsHeader from "../../global-components/StepsHeader";
import Sidebar from "../../global-components/Sidebar";
import Header from "../../global-components/Header";
import ReactCrop, { centerCrop, makeAspectCrop, } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from "./canvasPreview";
import useDebounceEffect from "./useDebounceEffect";
import { generateProduct_ } from '../../../services'
import ReplicateResult from './ReplicateResult'
import PickItemImg from '../../../assets/img/pick-item.jpeg'
import SelectDesign from "./SelectDesign";
import Result from "./Result";
import ProductComp from "./ProductComp";

const GetAlternative = ({ backHandler_, data, processId}) => {
    const [imageData, setImageData] = useState()
    const [filename, setFilename] = useState('')
    const [aspect, setAspect] = useState(16 / 9)
    const [crop, setCrop] = useState()
    const [loader, setLoader] = useState(false)
    const [completedCrop, setCompletedCrop] = useState()
    const [showNext6Step, setShowNext6Step] = useState(false)
    const imgRef = useRef(null)
    const previewCanvasRef = useRef(null)
    const imageCanvasRef = useRef(null)
    const [errorLog, setErrorLog] = useState(false)
    const [maskImage, setMaskImage] = useState('')
    const [guidance, setGuidance] = useState('')
    const [showReplicateComp, setShowReplicateComp] = useState(false)
    const [replicateData, setReplicateData] = useState([])
    const [BBox, setBox] = useState({})
    const [imageHW, setImageHW] = useState({})
    const [itemImage, setItemImage] = useState(false)
    const [itemImageSrc,setItemImageSrc]      = useState()
    const [showProductComp, setshowProductComp] = useState(false)
    const [productData, setProductData] = useState([])

    const uploadImageHandler = async (e) => {
        const file = e.target.files[0];
        setItemImage(file)
        const reader = new FileReader();   
        reader.readAsDataURL(file);
        reader.onload = (e) => {
        setItemImageSrc(e.target.result)
        };
    }


    useEffect(() => {
        let url_ = data.src
        const parts = url_.split('/');
        setFilename(parts[parts.length - 1])
        imageUrlToBase64(data.src).then((blob) => { setImageData(blob) })
    }, [data])

    const imageUrlToBase64 = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            };
            reader.onerror = reject;
        });
    };

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
                let imageData = canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, imageCanvasRef.current)
                let data = await imageData;
                setBox(completedCrop)
                setMaskImage(data)
            }
        }, 100, [completedCrop, showNext6Step],
    )

    const backToStep3Handler = () => {
        backHandler_()
    }

    const ImageCropHandler = (e) => {
        setCrop(e)
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setImageHW({ width, height })
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
        return centerCrop(makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
            mediaWidth,
            mediaHeight,
        )
    }

    const generateProduct = async (selectedData) => {
        setLoader(true);
        const image = imgRef.current
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height

        // crop Blob
        const previewCanvas = previewCanvasRef.current
        const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY)
        const ctxx = offscreen.getContext('2d')
        ctxx.drawImage(previewCanvas, 0, 0, previewCanvas.width, previewCanvas.height, 0, 0, offscreen.width, offscreen.height)
        const blob = await offscreen.convertToBlob({ type: 'image/png' })

        // mask blob
        const imageCanvasRef_ = imageCanvasRef.current
        const offscreen_ = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY)
        const ctxx_ = offscreen_.getContext('2d')
        ctxx_.drawImage(imageCanvasRef_, 0, 0, imageCanvasRef_.width, imageCanvasRef_.height, 0, 0, offscreen_.width, offscreen_.height)
        const blob_ = await offscreen_.convertToBlob({ type: 'image/png' })

        let timeStamp = new Date().getMilliseconds()
        let formData = new FormData();
        formData.append('image', blob, `mask-${timeStamp}.png`);
        formData.append('process_id', processId);

        formData.append('croped', blob_, `crop-${timeStamp}.png`);
        console.log(blob, blob_,)
        let responseData = await generateProduct_(formData)
        const { code, data } = responseData
        if (code === 200) {
            if (data.hasOwnProperty("body")) {
                let productData = data.body.data
                if (productData.length > 0) {
                    setProductData(productData)
                    setshowProductComp(true)
                } else {
                    setErrorLog('Product not found.')
                }
            } else {
                setErrorLog('Something went wrong please try again later')
            }
        } else {
            setErrorLog('Something went wrong please try again later')
        }
        setLoader(false);
    }

    const guidanceHandler = (e) => {
        const { value } = e.target
        setGuidance(value)
    }

    const handleImageClear = () =>{
        setItemImageSrc(null);
    }

    return (
        <>
            {
                !showProductComp ?
                    <div className="main-div">
                        <div className="container-fluid pt-5">
                            <div className="row ">
                                <Sidebar />
                                <div className="main-content container col-lg-9">
                                    <div className="container">
                                        <Header title={'Hasham Naeem'} />
                                        <hr className='hr w-100' />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="">
                                                    <div className="">
                                                        <h4>Click on Each of Your Selected Items to Get Alternatives</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <div className={'room-type-card'}>
                                                    <ReactCrop
                                                        crop={crop}
                                                        onComplete={(c) => setCompletedCrop(c)}
                                                        onChange={(_,) => ImageCropHandler(_)}
                                                    >
                                                        <img
                                                            ref={imgRef}
                                                            src={imageData}
                                                            className='selectedImg'
                                                            onLoad={e => onImageLoad(e)}
                                                        />
                                                    </ReactCrop>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="w-100 flex-wrap d-flex justify-content-center d-none">
                                            
                                            {!!completedCrop && (
                                                <div className="p-3 col-12 col-md-6">
                                                    <canvas
                                                        ref={imageCanvasRef}
                                                        style={{
                                                            border: '1px solid black',
                                                            width: completedCrop.width,
                                                            height: completedCrop.height,
                                                        }}
                                                        id="image_canvas"
                                                    />
                                                    <canvas
                                                        ref={previewCanvasRef}
                                                        style={{
                                                            border: '1px solid black',
                                                            width: imgRef.current?.width || 300,
                                                            height: imgRef.current?.height || 300,
                                                        }}
                                                        id="canvas"
                                                        className="d-none"
                                                    />
                                                </div>

                                            )}
                                        </div>
                                        <div className="row mt-0">
                                            <div className="col-md-10">
                                                <div className="pick-search mr-3 my-1 my-md-3">
                                                    <input className='w-100' value={guidance} onChange={(e) => { guidanceHandler(e) }} class="w-100" placeholder="Sofa etc.." id="floatingTextarea2" style={{ justifyContent: "center" }}></input>
                                                    {/* <input className='w-100' placeholder='e.g sofa, lamp (provide Only One suggestion)' type="search" name="" id="" /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-2 my-1 my-md-3">
                                                {errorLog &&
                                                    <div className="text-start text-danger m-4 text-center">
                                                        {errorLog}
                                                    </div>
                                                }
                                                <div className="pick-submit" onClick={() => { generateProduct() }}>
                                                    {loader ?
                                                        <button>
                                                            <div className="spinner-border text-light" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        </button>
                                                        :

                                                        <button>Submit</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <h4 className='my-3'>The Result</h4>
                                            <div className="col-md-4">
                                                <div className="select-design">
                                                    <input type="checkbox" id="selectDesign1" className="checkbox" />
                                                    <label htmlFor="selectDesign1">
                                                        <img className='rounded-4 image' width={'336px'} height={'224px'} src={PickItemImg} alt="" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="select-design">
                                                    <input type="checkbox" id="selectDesign2" className="checkbox" />
                                                    <label htmlFor="selectDesign2">
                                                        <img className='rounded-4 image' width={'336px'} height={'224px'} src={PickItemImg} alt="" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                            <div className="select-design">
                                                    <input type="checkbox" id="selectDesign3" className="checkbox" />
                                                    <label htmlFor="selectDesign3">
                                                        <img className='rounded-4 image' width={'336px'} height={'224px'} src={PickItemImg} alt="" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    // <Result  backHandler_={backHandler_} processId={processId} data={data} initState={true}/>
                    // <ReplicateResult imageHW={imageHW} data={replicateData} bbox={BBox} backToStepHandler={() => { setShowReplicateComp(false) }} processId={processId} />
                    <ProductComp backHandler_={backHandler_} data={productData} processId={processId} />
            }
        </>
    );
};

export default GetAlternative;
