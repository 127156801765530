import React, { useState, useCallback } from "react";
import Sidebar from "../../global-components/Sidebar";
import Header from "../../global-components/Header";
import ImageCard from "./ImageCard";
import { generateProduct_ } from '../../../services'
import BoundBoxCroper from './BoundBoxCroper'
import ProductComp from './ProductComp'
import { Link } from 'react-router-dom'
import Result from "./Result";
import GetAlternative from "./GetAlternative";
import { generateAlternativeImage } from '../../../services'

const SelectDesign = ({ backToStepHandler, imgData, formData, processId }) => {
    const [btnLoader, setBtnLoader] = useState(false)
    const [imageData, setImageData] = useState(imgData)
    const [selectedImage, setSelectedImage] = useState({})
    const [showProductComp, setshowProductComp] = useState(false)
    const [showStep4, setShowStep4] = useState(false)
    const [productData, setProductData] = useState([])
    const [errorLog, setErrorLog] = useState('')
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);

    const imageSelectHandler = (data_) => {
        setErrorLog('')
        setSelectedImage(data_)
    }

    const backToStepHandler_ = () => {
        backToStepHandler()
    }

    const imageUrlToBase64 = async (url) => {
        try {
            let timeStamp = new Date().getMilliseconds()
            const parts = url.split('/');
            const fileName = parts[parts.length - 1].replace('out-', `product-${timeStamp}`)
            const data = await fetch(url);
            const blob = await data.blob();
            return { blob, fileName }
        } catch (error) {
            return false
        }
    };

    // const generateProduct = async (selectedData, cropedData) => {
    //     let formData = new FormData();
    //     formData.append('image', selectedData.blob, selectedData.fileName);
    //     formData.append('process_id', processId);
    //     formData.append('croped', cropedData.blob, cropedData.filename);
    //     let responseData = await generateProduct_(formData)
    //     const { code, data } = responseData
    //     if (code === 200) {
    //         if (data.hasOwnProperty("body")) {
    //             let productData = data.body.data
    //             if (productData.length > 0) {
    //                 setProductData(productData)
    //                 setshowProductComp(true)
    //             } else {
    //                 setErrorLog('Product not found.')
    //             }
    //         } else {
    //             setErrorLog('Something went wrong please try again later')
    //         }
    //     } else {
    //         setErrorLog('Something went wrong please try again later')
    //     }
    // }

    // const bboxHandler = (cropedData_) => {
    //     imageUrlToBase64(selectedImage.src).then((data) => {
    //         if (data) {
    //             generateProduct(data, cropedData_)
    //             setShowStep4(true);
    //         }

    //     })
    // }

    const submitHandler = () => {
        setShowStep4(true);
    }

    const backHandler = () => {
        // setshowProductComp(false)
        // setProductData([])
        // console.log("sdfdfeferwerwtr");
    }

    const regenerate = async () => {
        setBtnLoader(true);
        try {
            let responseData = await generateAlternativeImage(formData);
            console.log(formData)
            console.log(responseData);
            const { code, data, message } = responseData
            console.log(data);
            if (code === 200) {
                const { result, process_id } = data
                
                console.log(result)
                let finalData = []
                result.map((image, index) => {
                    finalData.push({ id: index + 1, src: image })
                    return true
                })

                setImageData(finalData)
                console.log(finalData)
                setBtnLoader(false)
            } else {
                setBtnLoader(false)
                setImageData(imgData)
                setErrorLog(true)
            }
        } catch (error) {
            setBtnLoader(false)
            setImageData(imgData)
            setErrorLog(true)
        }
    }

    return (
        <>
            {!showStep4 ?
                <div className="main-div">
                    <div className="container-fluid pt-5">
                        <div className="row">
                            <Sidebar />
                            <div className="main-content col-lg-9">
                                <div className="container">
                                    <Header title={'Hasham Naeem'} />
                                    <hr className='hr w-100' />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="">
                                                <div className="">
                                                    <h4>Select Your Design</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="w-100  flex-wrap d-flex justify-content-center">
                                                        {
                                                            imageData.map((item, index) => {
                                                                return (
                                                                    <div key={index} onClick={() => { imageSelectHandler(item) }} className="select-design pointer_cursor col-12 col-md-4">
                                                                        <ImageCard image={item.src} checked={selectedImage.id === item.id} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        <div></div>
                                                    </div>
                                                    {errorLog &&
                                                        <div className="text-center text-danger">{errorLog}</div>
                                                    }
                                                    {/* {Object.keys(selectedImage).length > 0 &&
                                                        <BoundBoxCroper
                                                            data={selectedImage}
                                                            bbox={bbox}
                                                            bboxHandler_={(data) => { bboxHandler(data) }}
                                                            imageHW={imageHW}
                                                        />
                                                    } */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-5">
                                        <div className="col-md-6">
                                            <div onClick={() => { regenerate() }} className="buy-now text-center text-md-end mr-3">
                                                {btnLoader ?
                                                    <button>
                                                        <div className="spinner-border text-light" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </button>
                                                    :
                                                    <button>
                                                        Regenerate
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div onClick={() => { submitHandler() }} className="text-center text-md-start submit">
                                                <Link>Submit</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <GetAlternative backHandler_={backHandler} processId={processId} data={selectedImage} />
                // <ProductComp backHandler_={backHandler()} data={productData} processId={processId} />
            }
        </>
    );
};

export default SelectDesign;
