import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ResponsiveSidebar from "../sections-components/ResponsiveSidebar";
import { clearLocalStorageData, searchData } from '../../services'
import { useNavigate, useSearchParams } from "react-router-dom";
import { debounce } from '../../GlobalFunction'
import { useQuery } from '../../CustomContext';
import SearchIcon from '../../assets/img/search-icon.svg';
import NotificationIcon from '../../assets/img/notification-icon.png';
import Dummy from '../../assets/img/dummy.png';
import Dropdown from '../../assets/img/dropdown-icon.svg';

let timeout = null;
const Header = (props) => {
  const { searchQuery, updateQuery } = useQuery();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue_, setSearchValue] = useState("");

  useEffect(() => {
    setActiveTab(getActiveTab(location.pathname));
  }, [location.pathname]);


  useEffect(() => {
    setSearchValue(searchQuery?.query)
  }, [])



  const handleLinkClick = (tabName) => {
    setActiveTab(tabName);
  };

  const getActiveTab = (pathname) => {
    if (pathname === "/home") {
      return "Home";
    } else if (pathname === "/profile") {
      return "Profile";
    } else if (pathname === "/notifications") {
      return "Notifications";
    } else if (pathname === "/history") {
      return "History";
    } else if (pathname === "/faqs") {
      return "Faqs";
    } else if (pathname === "/contact") {
      return "Contact";
    } else if (pathname === "/terms-and-privacy") {
      return "Terms";
    } else {
      return "";
    }
  };

  const logOutHandler = () => {
    clearLocalStorageData()
    setTimeout(() => {
      navigate('/login')
    }, 900)
  }

  const debouncedHandleInputChange = (e) => {
    const { value } = e.target
    updateQuery({ ...searchQuery, query: value });
    setSearchValue(value)
    if (location.pathname === '/search') {
      if (value == '') {
        navigate('/')
      } else {
        setSearchParams(params => {
          params.set("query", value);
          return params;
        });
      }
    } else {
      navigate(`/search?query=${value}`)
    }

    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(async () => {
      let responseData = await searchData(value)
      const { code, data } = responseData
      updateQuery({ data, query: value });
    }, 500)

  }

  return (
    <>
      <div className="row align-items-center justify-content-between m-0">
        <div className="col-12 d-md-none">
          <div className='terms-search d-flex'>
            <img className="search-img" src={SearchIcon} alt="" />
            <input
              type="search"
              className="form-control"
              placeholder="Search"
              onChange={debouncedHandleInputChange}
              value={searchValue_}
              autoFocus
            />
          </div>
        </div>

        <div className="col-3 col-md-4" style= {{ width: "max-content" }} >
          <div className='title'>
            <h2>{props.title}</h2>
          </div>
        </div>

        <div className="col-2 col-md-4 d-none d-md-inline">
          <div className='terms-search d-flex'>
            <img className="search-img" src={SearchIcon} alt="" />
            <input
              type="search"
              className="form-control"
              placeholder="Search"
              onChange={debouncedHandleInputChange}
              value={searchValue_}
              autoFocus
            />
          </div>
        </div>

        <div className="col-1 text-center notification-icon">
          <button>
            <Link to={'/notifications'}>
              <img src={NotificationIcon} alt="" />
            </Link>
          </button>
        </div>

        <div className="col-1 col-md-2 px-0 text-center profile-div"  style= {{ width: "max-content" }}>
          <button className='d-flex justify-content-between'>
            <img className="profile-pic" src={Dummy} alt="" />
            <a
              href="#"
              className="d-block link-dark text-decoration-none dropdown-toggle ms-3"
              id="dropdownUser1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style= {{ position: 'relative', top: '13px' }}
            >
            </a>
            <ul
              className="dropdown-menu rounded-5 text-small shadow mt-2 border-0"
              aria-labelledby="dropdownUser1"
            >
              <li>
                <Link className="dropdown-item" to={"/profile"}>
                  Profile
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" onClick={() => { logOutHandler() }}>
                  Sign out
                </Link>
              </li>
            </ul>
          </button>
        </div>

      </div>
    </>
  );
};

export default Header;
