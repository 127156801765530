import React from "react";

const Card = (props) => {
  const {text="White Home Theme",content="As Home Shopper Ai Through...."} = props
  return (
    <a href="#" className="text-decoration-none ">
      <img
        src={props.img}
        className="w-100 border shadow-sm rounded-5 latest-upload-project-img"
      />
      <h5 className="mt-3 p-0 m-0 text-dark">{text}</h5>
      <p className="p-0 m-0 text-secondary">{content}</p>
      <div className="d-flex align-items-center mt-2">
        <div className="d-flex text-dark align-items-center">
          <i className="material-icons fs-5">
            {props.icons == true ? "schedule" : ""}
          </i>
          <p className="fw-5 p-0 m-0 ms-2">{props.time ? props.time : ""}</p>
        </div>
        <div className="d-flex text-dark align-items-center ms-4">
          <i className="material-icons fs-5">
            {props.icons == true ? "calendar_month" : ""}
          </i>
          <p className="fw-5 p-0 m-0 ms-2">{props.date ? props.date : ""}</p>
        </div>
      </div>
    </a>
  );
};

export default Card;
