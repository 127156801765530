import React, { useCallback, useEffect, useState } from 'react'
import {LoginSocialGoogle,LoginSocialFacebook,LoginSocialLinkedin,LoginSocialApple,} from 'reactjs-social-login'
import Facebook from '../assets/img/facebook.png';
import Apple from '../assets/img/apple.png';
import LinkedIn from '../assets/img/in.png';
import Google from '../assets/img/google.png';
import {socialAuth,setLocalStorageData,getLinkedinProfile} from '../services'
import { useNavigate } from 'react-router-dom';

const REDIRECT_URI = window.location.href;

const App = ({loaderHandler}) => {
    const navigate = useNavigate()
    const [errorLog,setErrorLog]     = useState(false)



    const onLoginStart = useCallback(() => {
        console.log('auth');
    }, [])


    const socialAuthHanlder = async(authData) =>{
        try {
            loaderHandler(true)
            let  responseData = await socialAuth(authData)
            const {code,data} = responseData;
            if (code === 200){
                const {refresh,access,info} = data
                setLocalStorageData('token_',refresh,'token')
                setLocalStorageData('token',access,'token')
                setLocalStorageData('info',info)
                navigate('/')
                loaderHandler(false)
            } else {
                loaderHandler(false)
            }
        } catch (error) {
            loaderHandler(false)
        }
        
    } 

    return (
        <>
            <LoginSocialGoogle
                scope={'email profile openid'}
                isOnlyGetToken={false}
                client_id={process.env.REACT_APP_GG_APP_ID || ''}
                onLoginStart={onLoginStart}
                onResolve={({ provider, data }) => {
                    data.provider = provider
                    socialAuthHanlder(data)
                }}
                onReject={(err) => {
                    console.log(err)
                }}
            >
                <img src={Google} width={45} className='mb-4 mx-2 logo border p-1 rounded-3 cr-pointer social-logo'/>
            </LoginSocialGoogle>

            {/* <LoginSocialFacebook
                isOnlyGetToken={false}
                appId={process.env.REACT_APP_FB_APP_ID || ''}
                onLoginStart={onLoginStart}
                onResolve={({ provider, data }) => {
                    data.provider = provider
                    socialAuthHanlder(data)
                }}
                onReject={(err) => {
                    console.log(err)
                }}
            >
                <img src={Facebook} width={45} className='mb-4 mx-2 logo border p-1 rounded-3 cr-pointer social-logo'/>
            </LoginSocialFacebook> */}

            {/* <LoginSocialApple
                client_id={process.env.REACT_APP_APPLE_ID || ''}
                scope={'name email'}
                redirect_uri={REDIRECT_URI}
                onLoginStart={onLoginStart}
                onResolve={({ provider, data }) => {
                    data.provider = provider
                    socialAuthHanlder(data)
                }}
                onReject={err => {
                    console.log(err);
                }}
            >
                <img src={Apple} width={45} className='mb-4 mx-2 logo border p-1 rounded-3 cr-pointer social-logo'/>
            </LoginSocialApple> */}

            <LoginSocialLinkedin
                scope={'email profile openid'}
                isOnlyGetToken
                client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ''}
                client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ''}
                redirect_uri={REDIRECT_URI}
                onLoginStart={onLoginStart}
                onResolve={({ provider, data }) => {
                    data.provider = provider
                    socialAuthHanlder(data)
                }}
                onReject={(err) => {
                    console.log(err,'err');
                    console.log(err)
                }}
            >
                <img src={LinkedIn} width={45} className='mb-4 mx-2 logo border p-1 rounded-3 cr-pointer social-logo'/>
            </LoginSocialLinkedin>

        </>
    )
}

export default App