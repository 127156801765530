import React, { useEffect, useState } from 'react'
import Card from '../sections-components/Card';
import APIs from '../../Api';
import {getHistory} from '../../services'

export default function ProjectHistory({ishow=true,type,isSearch,searchData}) {
    const [historyData,setHistoryData] = useState([])
    const [loader,setLoader]           = useState(false)

    useEffect(()=>{
        if (isSearch){
            setHistoryData(searchData)
        } else {
            getOldHistory()
        }
        
    },[])

    const getOldHistory = async() => {
        setLoader(true)
        let responseData = await getHistory(type)
        const {code,data} = responseData;
        if (code === 200){
        setHistoryData(data)
        setLoader(false)
        } else {
        setLoader(false)
        }
    }
    
    const goToUrlHAndler = (data) =>{
        if (data.redirect_url){
        window.open(data.redirect_url,'_blank');
        }
    }
    
    return (
        <div className="home-page-content mt-4">
            <div className={"row"+(loader?" disabled_activate history":"") +(ishow?" deactivate_content":"") +(historyData.length == 0 || !historyData.length ?" disabled_activate text-center":"")}>
                { !loader ?
                    historyData.length > 0  ?
                    historyData.map((item,index)=>{
                    let imageSrc= `${APIs.DOMAIN}${item.main_image}`
                    let data = new Date(item.created_at)
                    let hours = ("0" + data.getHours()).slice(-2);
                    let minutes = ("0" + data.getMinutes()).slice(-2);
                    let seconds = ("0" + data.getSeconds()).slice(-2);
                    let timeFormat = `${hours}:${minutes}:${seconds}`;
                    let day = ("0" + data.getDate()).slice(-2);
                    let month = ("0" + (data.getMonth() + 1)).slice(-2);
                    let year = data.getFullYear();
                    let dateFormat = `${day}/${month}/${year}`;
                    return(
                        <div onClick={()=>{goToUrlHAndler(item)}} key={index} className="col-lg-4 col-md-6 col-sm-12 my-3">
                        <Card
                            img={imageSrc}
                            icons={true}
                            date={dateFormat}
                            time={timeFormat}
                            text={`${item.room_type?`${item.room_type},`:''}${item.style_type?item.style_type:''}`}
                            content={item.prompt}
                        />
                        </div>
                    )
                    })
                    :
                        ishow ? <h5>Not Found</h5> :<h6 className='text-center'>Not Found</h6>
                :
                    <div className="spinner-border text-dark" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                }
                
            </div>
            {/* Load more btn */}
            {/* <div className="text-center my-4">
                <button className="btn btn-secondary btn-sm">Load more...</button>
            </div> */}
        </div>
    )
}
