import React,{useEffect} from 'react';
import {getLocalStorageData} from '../services';
import { useNavigate } from 'react-router-dom';

export default function PublicRoute({children}) {
    const  navigate = useNavigate()
    useEffect(()=>{
        let token_ = getLocalStorageData('token_','simple')
        if (token_){
            navigate('/')
        }
    },[])
    return children
}
