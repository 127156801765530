import React from "react";
import Sidebar from "../global-components/Sidebar";
import Header from "../global-components/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProjectHistory from "../global-components/ProjectHistory";

const History = () => {

  return (
    <>
      <div className="main-div">
        <div className="container-fluid pt-5">
          <div className="row">
            <Sidebar />
            <div className="main-content col-lg-9">
              <div className="container">
                <Header title={'History'} />
                <hr className='hr w-100' />
                <div className="row">
                  <div className="col-md-12">
                    <div className="">
                      <div className="">
                        <h4>Examine your past projects</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <ProjectHistory ishow={true} type={"all"} isSearch={false} searchData={[]}  />
              </div>
            </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default History;
