import React, { useEffect, useState, useRef } from "react";
import StepsHeader from "../../global-components/StepsHeader";
import Sidebar from "../../global-components/Sidebar";
import Header from "../../global-components/Header";
import ReactCrop, { centerCrop, makeAspectCrop, } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from "./canvasPreview";
import useDebounceEffect from "./useDebounceEffect";
import { generateImage_ } from '../../../services'
import ReplicateResult from './ReplicateResult'
import PickItemImg from '../../../assets/img/pick-item.jpeg'
import SelectDesign from "./SelectDesign";
import Result from "./Result";

const BoundBox = ({ backHandler_, data, processId, initState }) => {
    const [imageData, setImageData] = useState()
    const [filename, setFilename] = useState('')
    const [aspect, setAspect] = useState(16 / 9)
    const [crop, setCrop] = useState()
    const [loader, setLoader] = useState(false)
    const [completedCrop, setCompletedCrop] = useState()
    const [showNext6Step, setShowNext6Step] = useState(false)
    const imgRef = useRef(null)
    const previewCanvasRef = useRef(null)
    const imageCanvasRef = useRef(null)
    const [errorLog, setErrorLog] = useState(false)
    const [maskImage, setMaskImage] = useState('')
    const [guidance, setGuidance] = useState('')
    const [showReplicateComp, setShowReplicateComp] = useState(false)
    const [replicateData, setReplicateData] = useState([])
    const [BBox, setBox] = useState({})
    const [imageHW, setImageHW] = useState({})
    const [itemImage, setItemImage] = useState(false)
    const [itemImageSrc,setItemImageSrc]      = useState()

    const uploadImageHandler = async (e) => {
        const file = e.target.files[0];
        setItemImage(file)
        const reader = new FileReader();   
        reader.readAsDataURL(file);
        reader.onload = (e) => {
        setItemImageSrc(e.target.result)
        };
    }


    useEffect(() => {
        if (!initState) {
            let url_ = data.src
            const parts = url_.split('/');
            setFilename(parts[parts.length - 1])
            imageUrlToBase64(data.src).then((blob) => { setImageData(blob) })
        } else {
            setImageData(data)
        }
    }, [data])

    const imageUrlToBase64 = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            };
            reader.onerror = reject;
        });
    };

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
                let imageData = canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, imageCanvasRef.current)
                let data = await imageData;
                setBox(completedCrop)
                setMaskImage(data)
            }
        }, 100, [completedCrop, showNext6Step],
    )

    const backHandler = () => {
        setShowReplicateComp(false);
    }

    const ImageCropHandler = (e) => {
        setCrop(e)
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setImageHW({ width, height })
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
        return centerCrop(makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
            mediaWidth,
            mediaHeight,
        )
    }

    async function onDownloadCropClick() {
        setLoader(true)
        setErrorLog(false)
        const image = imgRef.current
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height

        // crop Blob
        const previewCanvas = previewCanvasRef.current
        const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY)
        const ctxx = offscreen.getContext('2d')
        ctxx.drawImage(previewCanvas, 0, 0, previewCanvas.width, previewCanvas.height, 0, 0, offscreen.width, offscreen.height)
        const blob = await offscreen.convertToBlob({ type: 'image/png' })

        // mask blob
        const imageCanvasRef_ = imageCanvasRef.current
        const offscreen_ = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY)
        const ctxx_ = offscreen_.getContext('2d')
        ctxx_.drawImage(imageCanvasRef_, 0, 0, imageCanvasRef_.width, imageCanvasRef_.height, 0, 0, offscreen_.width, offscreen_.height)
        const blob_ = await offscreen_.convertToBlob({ type: 'image/png' })

        let timeStamp = new Date().getMilliseconds()
        let formData = new FormData();
        formData.append('mask', blob, `mask-${timeStamp}.png`);
        formData.append('crop', blob_, `crop-${timeStamp}.png`);
        formData.append('guidance', guidance);
        formData.append('process_id', processId);
        formData.append('bbox', JSON.stringify(BBox))

        try {
            let responseData = await generateImage_(formData);
            const { code, data } = responseData
            if (code === 200) {
                if (Object.keys(data).length > 0) {
                    const { result, process_id, bbox } = data
                    let finalData = []
                    result.map((image, index) => {
                        finalData.push({ id: index + 1, src: image })
                        return true
                    })
                    setReplicateData(finalData)
                    setBox(bbox)
                    setShowReplicateComp(true)
                    setLoader(false)
                } else {
                    setLoader(false)
                    setErrorLog('Something went wrong please try again later')
                }

            } else {
                setLoader(false)
                setErrorLog('Something went wrong please try again later')
            }
        } catch (error) {
            setLoader(false)
            setErrorLog('Something went wrong please try again later')
        }

    }


    const guidanceHandler = (e) => {
        const { value } = e.target
        setGuidance(value)
    }

    const handleImageClear = () =>{
        setItemImageSrc(null);
    }

    return (
        <>
            {
                !showReplicateComp ?
                    <div className="main-div">
                        <div className="container-fluid pt-5">
                            <div className="row ">
                                <Sidebar />
                                <div className="main-content container col-lg-9">
                                    <div className="container">
                                        <Header title={'Hasham Naeem'} />
                                        <hr className='hr w-100' />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="">
                                                    <div className="">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-6">
                                                <h4 pt-5 >Draw a bounding box around any item in your image</h4>
                                                <div className={'room-type-card'}>
                                                    <ReactCrop
                                                        crop={crop}
                                                        onComplete={(c) => setCompletedCrop(c)}
                                                        onChange={(_,) => ImageCropHandler(_)}
                                                    >
                                                        <img
                                                            ref={imgRef}
                                                            src={imageData}
                                                            className='selectedImg'
                                                            onLoad={e => onImageLoad(e)}
                                                        />
                                                    </ReactCrop>
                                                </div>
                                            </div>
                                            <div className="col-md-6 my-3 my-md-0 d-flex flex-column">
                                                <div className="row">
                                                    <h4>Get AI Suggestions By Selecting An Item</h4>
                                                </div>
                                                <div className="row flex-grow-1 d-flex align-items-stretch">
                                                    <div className="upload-image mb-4 mb-md-0 flex-grow-1">
                                                        <input type="file"
                                                            onChange={(e) => { uploadImageHandler(e) }}
                                                            id="imageInput" style={{ display: "none" }}
                                                            accept="image/*"
                                                        />
                                                        <label htmlFor="imageInput" id="roomImageUploader" className="w-100 h-100">
                                                            <div className="dragdrop p-3 shadow-sm">
                                                                {itemImageSrc ? (
                                                                    <div>
                                                                        <button onClick={handleImageClear}>Clear</button>
                                                                        <img src={itemImageSrc} height={'100%'} width={'100%'} alt="Uploaded Preview" className="img-fluid" />
                                                                    </div>
                                                                ) : (
                                                                    <div className="h-100 d-flex align-items-center justify-content-center">
                                                                        <i className="material-icons">cloud_upload</i>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-100 flex-wrap d-flex justify-content-center d-none">
                                            
                                            {!!completedCrop && (
                                                <div className="p-3 col-12 col-md-6">
                                                    <canvas
                                                        ref={imageCanvasRef}
                                                        style={{
                                                            border: '1px solid black',
                                                            width: completedCrop.width,
                                                            height: completedCrop.height,
                                                        }}
                                                        id="image_canvas"
                                                    />
                                                    <canvas
                                                        ref={previewCanvasRef}
                                                        style={{
                                                            border: '1px solid black',
                                                            width: imgRef.current?.width || 300,
                                                            height: imgRef.current?.height || 300,
                                                        }}
                                                        id="canvas"
                                                        className="d-none"
                                                    />
                                                </div>

                                            )}
                                        </div>
                                        <div className="row mt-3 mt-md-5">
                                            <h4>Alternatively, What Do You Want To Replace</h4>
                                            <div className="col-md-10">
                                                <div className="pick-search mr-3 my-1 my-md-3">
                                                    <input className='w-100' value={guidance} onChange={(e) => { guidanceHandler(e) }} class="w-100" placeholder="e.g sofa, lamp (provide Only One suggestion)" id="floatingTextarea2" style={{ justifyContent: "center" }}></input>
                                                    {/* <input className='w-100' placeholder='e.g sofa, lamp (provide Only One suggestion)' type="search" name="" id="" /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-2 my-1 my-md-3">
                                                {errorLog &&
                                                    <div className="text-start text-danger m-4 text-center">
                                                        {errorLog}
                                                    </div>
                                                }
                                                <div className="pick-submit" onClick={() => { onDownloadCropClick() }}>
                                                    {loader ?
                                                        <button>
                                                            <div className="spinner-border text-light" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        </button>
                                                        :

                                                        <button>Submit</button>
                                                    }
                                                </div>
                                                {/* <Link to={'/select-design'}> */}
                                                {/* <div className="pick-submit">
                                                    <button>Submit</button>
                                                </div> */}
                                                {/* </Link> */}
                                            </div>
                                        </div>
                                        {/* <div className="row mt-2" style={{ justifyContent: "center" }} >
                                            <div class="col-6">
                                                <div class="form-floating">
                                                    <textarea value={guidance} onChange={(e) => { guidanceHandler(e) }} class="form-control promt" placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: "50px !important", justifyContent: "center" }}></textarea>
                                                    <label style={{ fontSize: '19px' }} htmlFor="floatingTextarea2">Enter a guidance text</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-4 mb-5">
                                            {errorLog &&
                                                <div className="text-start text-danger m-4 text-center">
                                                    {errorLog}
                                                </div>
                                            }
                                            <div className="btn btn-primary fw-5 generatebtn authbtn mx-2" onClick={() => { onDownloadCropClick() }}>
                                                {loader ?
                                                    <div className="spinner-border text-light" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    :
                                                    'Submit'
                                                }
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <h4 className='my-3'>Click to Buy an item now</h4>
                                            <div className="col-md-4">
                                                <div className="select-design">
                                                    <input type="checkbox" id="selectDesign1" className="checkbox" />
                                                    <label htmlFor="selectDesign1">
                                                        <img className='rounded-4 image' width={'336px'} height={'224px'} src={PickItemImg} alt="" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="select-design">
                                                    <input type="checkbox" id="selectDesign2" className="checkbox" />
                                                    <label htmlFor="selectDesign2">
                                                        <img className='rounded-4 image' width={'336px'} height={'224px'} src={PickItemImg} alt="" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                            <div className="select-design">
                                                    <input type="checkbox" id="selectDesign3" className="checkbox" />
                                                    <label htmlFor="selectDesign3">
                                                        <img className='rounded-4 image' width={'336px'} height={'224px'} src={PickItemImg} alt="" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    // <SelectDesign imageHW={imageHW} data={replicateData} bbox={BBox} backToStepHandler={() => { setShowReplicateComp(false) }} processId={processId} />
                    <Result imageHW={imageHW} data={replicateData} bbox={BBox} backHandler_={ backHandler } processId={processId} />
                    
                    // <ReplicateResult imageHW={imageHW} data={replicateData} bbox={BBox} backToStepHandler={() => { setShowReplicateComp(false) }} processId={processId} />
            }
        </>
    );
};

export default BoundBox;
