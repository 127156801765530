import APIs from "./Api";


export const userRegistrationHandler = async(data) =>{
    try {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        };
        const response = await fetch(APIs.SIGNUP_API, requestOptions)
        let data_ = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
  
}

export const userLogin = async(data) =>{
    try {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        };
        const response = await fetch(APIs.LOGIN_API, requestOptions)
        let data_ = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
  
}

export const socialAuth = async(data) =>{
    try {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        };
        const response = await fetch(APIs.SOCIAL_AUTH, requestOptions)
        let data_ = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
  
}

export const forgotPassword = async(data) =>{
    try {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        };
        const response = await fetch(APIs.FORGOT_PASSWORD, requestOptions)
        let data_ = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
  
}

export const otpValidation= async(data) =>{
    try {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        };
        const response = await fetch(APIs.OTP_VALIDATION, requestOptions)
        let data_ = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
}

export const resendOtp = async(token) =>{
    try {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers,
        };
        const response = await fetch(APIs.OTP_VALIDATION+"?token="+token, requestOptions)
        let data_ = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
}

export const generateAlternativeImage = async(data) =>{
    try {
        let header_     = authHeader(data)
        const response  = await fetch(APIs.GENERATE_ALTERNATIVE_IMAGE,header_)
        let data_       = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
}

export const uploadInitImage = async(data) =>{
    try {
        let header_     = authHeader(data)
        const response  = await fetch(APIs.UPLOAD_INIT_IMAGE,header_)
        let data_       = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
}

export const generateImage_ = async(data,processID,fileName) =>{
    try {
        let header_     = imageAuthHeader(data,'POST',processID,fileName)
        const response  = await fetch(APIs.GENERATE_IMAGE,header_)
        let data_       = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
}

export const generateProduct_ = async(data) =>{
    try {
        let header_     = imageAuthHeader(data,'POST')
        const response  = await fetch(APIs.GENERATE_PRODUCT,header_)
        let data_       = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
}

export const saveData = async(data) =>{
    try {
        let header_     = authHeader(data)
        const response  = await fetch(APIs.SAVE_INFO,header_)
        let data_       = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
}

export const getTypes = async(type='room') =>{
    try {
        let header_     = authHeader(false,'GET')
        const response  = await fetch(APIs.GET_TYPES+type,header_)
        let data_       = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": [],
            "message": error
        }
    }
}

export const updatePassword= async(data) =>{
    try {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        };
        const response = await fetch(APIs.UPDATE_PASSWORD, requestOptions)
        let data_ = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
  
}

export const updateProfile = async(data) =>{
    try {
        let accessToken = getLocalStorageData('token','simple')
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${accessToken}`);
        var requestOptions = {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        };
        const response = await fetch(APIs.EDIT_PROFILE, requestOptions)
        let data_ = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
  
}

export const getLocalStorageData = (key,type) => {
    try {
        let  data = false
        let  dataType = ''
        if (type === 'object'){
            dataType =  '{}'
        } else {
            dataType = '[]'
        }  
        if (type !=='simple'){
            data = localStorage.getItem(key) || dataType
            data = JSON.parse(data)
        } else {
            data = localStorage.getItem(key)
        }
        
        return data
    } catch (error) {
        return false
    }
}

export const setLocalStorageData = (key,data,type='object') =>{
    if (type === 'object'){data = JSON.stringify(data)}
    localStorage.setItem(key,data)
}

export const clearLocalStorageData  = () =>{
    localStorage.clear()
    window.location.reload()
}

export const getAccessToken = async() =>{
    try {
        let refresh = getLocalStorageData('token_','simple')
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers,
            body: JSON.stringify({refresh}),
        };
        const response      = await fetch(APIs.ACCESS_TOKEN, requestOptions)
        let data_           = await response.json()
        const {code,data}   =   data_
        if (code === 200){
            setLocalStorageData('token',data.access,'simple')
        } else {
            clearLocalStorageData()
            window.location.reload()
        }
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
  
}

export const contactUs = async(data) =>{
    try {
        let accessToken = getLocalStorageData('token','simple')
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${accessToken}`);
        var requestOptions = {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        };
        const response  = await fetch(APIs.CONTACT_US,requestOptions)
        let data_       = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
}

export const getHistory = async(type) =>{
    const params = {type};
    const queryString = new URLSearchParams(params).toString();
    const urlWithParams = `${APIs.GET_HISTORY}?${queryString}`;
    try {
        let header_     = authHeader(false,'GET')
        const response  = await fetch(urlWithParams,header_)
        let data_       = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
}

export const getNotifications = async(type) =>{
    try {
        let header_     = authHeader(false,'GET')
        const response  = await fetch(APIs.GET_NOTIFICATIONS,header_)
        let data_       = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
}

export const searchData = async(query) =>{
    try {
        const params = {query};
        const queryString = new URLSearchParams(params).toString();
        const urlWithParams = `${APIs.SEARCH}?${queryString}`;
        let header_     = authHeader(false,'GET')
        const response  = await fetch(urlWithParams,header_)
        let data_       = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": {},
            "message": error
        }
    }
}

export const relatedTemplate = async(query) =>{
    try {
        const params = {query};
        const queryString = new URLSearchParams(params).toString();
        let templateAPI = `${APIs.RELATED_TEMPLATE}?${queryString}`;
        let header_     = authHeader(false,'GET')
        const response  = await fetch(templateAPI,header_)
        let data_       = await response.json()
        return data_
    } catch (error) {
        return {
            "status":'error',
            "code": 400,
            "data": [],
            "message": error
        }
    }
}

const authHeader = (data,method='POST') =>{
    var headers = new Headers();
    let accessToken = getLocalStorageData('token','simple')
    headers.append("Authorization", `Bearer ${accessToken}`);
    if (data){
        return { method:method,headers,body:data};
    } else {
        return { method:method,headers};
    }
}

const imageAuthHeader = (data,method='POST') =>{
    var headers = new Headers();
    let accessToken = getLocalStorageData('token','simple')
    headers.append("Authorization", `Bearer ${accessToken}`);
    return { method:method,headers,body:data};
}
