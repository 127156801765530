export const countryCode = [

    {
      "name": "India",
      "dial_code": "+91",
      "code": "IN",
      "locale": "en-IN"
    },
    {
      "name": "Pakistan",
      "dial_code": "+92",
      "code": "PK",
      "locale": "en-PK"
    },
    {
      "name": "United States",
      "dial_code": "+1",
      "code": "US",
      "locale": "en-US"
    },
    {
      "name": "Afghanistan",
      "dial_code": "+93",
      "code": "AF",
      "locale": "fa-AF"
    },
    {
      "name": "Albania",
      "dial_code": "+355",
      "code": "AL",
      "locale": "sq-AL"
    },
    {
      "name": "Algeria",
      "dial_code": "+213",
      "code": "DZ",
      "locale": "ar-DZ"
    },
    {
      "name": "Andorra",
      "dial_code": "+376",
      "code": "AD",
      "locale": "ca-AD"
    },
    {
      "name": "Angola",
      "dial_code": "+244",
      "code": "AO",
      "locale": "pt-AO"
    },
    {
      "name": "Anguilla",
      "dial_code": "+1264",
      "code": "AI",
      "locale": "en-AI"
    },
    {
      "name": "Antigua and Barbuda",
      "dial_code": "+1268",
      "code": "AG",
      "locale": "en-AG"
    },
    {
      "name": "Argentina",
      "dial_code": "+54",
      "code": "AR",
      "locale": "es-AR"
    },
    {
      "name": "Aruba",
      "dial_code": "+297",
      "code": "AW",
      "locale": "nl-AW"
    },
    {
      "name": "Australia",
      "dial_code": "+61",
      "code": "AU",
      "locale": "en-AU"
    },
    {
      "name": "Austria",
      "dial_code": "+43",
      "code": "AT",
      "locale": "de-AT"
    },
    {
      "name": "Azerbaijan",
      "dial_code": "+994",
      "code": "AZ",
      "locale": "az-AZ"
    },
    {
      "name": "Bahamas",
      "dial_code": "+1242",
      "code": "BS",
      "locale": "en-BS"
    },
    {
      "name": "Bahrain",
      "dial_code": "+973",
      "code": "BH",
      "locale": "ar-BH"
    },
    {
      "name": "Bangladesh",
      "dial_code": "+880",
      "code": "BD",
      "locale": "bn-BD"
    },
    {
      "name": "Belarus",
      "dial_code": "+375",
      "code": "BY",
      "locale": "be-BY"
    },
    {
      "name": "Belgium",
      "dial_code": "+32",
      "code": "BE",
      "locale": "fr-BE"
    },
    {
      "name": "Benin",
      "dial_code": "+229",
      "code": "BJ",
      "locale": "fr-BJ"
    },
    {
      "name": "Bermuda",
      "dial_code": "+1441",
      "code": "BM",
      "locale": "en-BM"
    },
    {
      "name": "Bhutan",
      "dial_code": "+975",
      "code": "BT",
      "locale": "dz-BT"
    },
    {
      "name": "Bolivia, Plurinational State of",
      "dial_code": "+591",
      "code": "BO",
      "locale": "es-BO"
    },
    {
      "name": "Bosnia and Herzegovina",
      "dial_code": "+387",
      "code": "BA",
      "locale": "bs-BA"
    },
    {
      "name": "Botswana",
      "dial_code": "+267",
      "code": "BW",
      "locale": "en-BW"
    },
    {
      "name": "Brazil",
      "dial_code": "+55",
      "code": "BR",
      "locale": "pt-BR"
    },
    {
      "name": "Bulgaria",
      "dial_code": "+359",
      "code": "BG",
      "locale": "bg-BG"
    },
    {
      "name": "Burkina Faso",
      "dial_code": "+226",
      "code": "BF",
      "locale": "fr-BF"
    },
    {
      "name": "Canada",
      "dial_code": "+1",
      "code": "CA",
      "locale": "en-CA"
    },
    {
      "name": "Central African Republic",
      "dial_code": "+236",
      "code": "CF",
      "locale": "fr-CF"
    },
    {
      "name": "Chile",
      "dial_code": "+56",
      "code": "CL",
      "locale": "es-CL"
    },
    {
      "name": "China",
      "dial_code": "+86",
      "code": "CN",
      "locale": "zh-CN"
    },
    {
      "name": "Colombia",
      "dial_code": "+57",
      "code": "CO",
      "locale": "es-CO"
    },
    {
      "name": "Congo, The Democratic Republic of the Congo",
      "dial_code": "+243",
      "code": "CD",
      "locale": "fr-CD"
    },
    {
      "name": "Costa Rica",
      "dial_code": "+506",
      "code": "CR",
      "locale": "es-CR"
    },
    {
      "name": "Cuba",
      "dial_code": "+53",
      "code": "CU",
      "locale": "es-CU"
    },
    {
      "name": "Cyprus",
      "dial_code": "+357",
      "code": "CY",
      "locale": "el-CY"
    },
    {
      "name": "Czech Republic",
      "dial_code": "+420",
      "code": "CZ",
      "locale": "cs-CZ"
    },
    {
      "name": "Denmark",
      "dial_code": "+45",
      "code": "DK",
      "locale": "da-DK"
    },
    {
      "name": "Dominican Republic",
      "dial_code": "+1849",
      "code": "DO",
      "locale": "es-DO"
    },
    {
      "name": "Ecuador",
      "dial_code": "+593",
      "code": "EC",
      "locale": "es-EC"
    },
    {
      "name": "Egypt",
      "dial_code": "+20",
      "code": "EG",
      "locale": "ar-EG"
    },
    {
      "name": "El Salvador",
      "dial_code": "+503",
      "code": "SV",
      "locale": "es-SV"
    },
    {
      "name": "Estonia",
      "dial_code": "+372",
      "code": "EE",
      "locale": "et-EE"
    },
    {
      "name": "Faroe Islands",
      "dial_code": "+298",
      "code": "FO",
      "locale": "fo-FO"
    },
    {
      "name": "Fiji",
      "dial_code": "+679",
      "code": "FJ",
      "locale": "fj-FJ"
    },
    {
      "name": "Finland",
      "dial_code": "+358",
      "code": "FI",
      "locale": "fi-FI"
    },
    {
      "name": "France",
      "dial_code": "+33",
      "code": "FR",
      "locale": "fr-FR"
    },
    {
      "name": "French Guiana",
      "dial_code": "+594",
      "code": "GF",
      "locale": "fr-GF"
    },
    {
      "name": "French Polynesia",
      "dial_code": "+689",
      "code": "PF",
      "locale": "fr-PF"
    },
    {
      "name": "Georgia",
      "dial_code": "+995",
      "code": "GE",
      "locale": "ka-GE"
    },
    {
      "name": "Germany",
      "dial_code": "+49",
      "code": "DE",
      "locale": "de-DE"
    },
    {
      "name": "Ghana",
      "dial_code": "+233",
      "code": "GH",
      "locale": "en-GH"
    },
    {
      "name": "Greece",
      "dial_code": "+30",
      "code": "GR",
      "locale": "el-GR"
    },
    {
      "name": "Greenland",
      "dial_code": "+299",
      "code": "GL",
      "locale": "kl-GL"
    },
    {
      "name": "Guadeloupe",
      "dial_code": "+590",
      "code": "GP",
      "locale": "fr-GP"
    },
    {
      "name": "Guernsey",
      "dial_code": "+44",
      "code": "GG",
      "locale": "en-GG"
    },
    {
      "name": "Guyana",
      "dial_code": "+595",
      "code": "GY",
      "locale": "en-GY"
    },
    {
      "name": "Honduras",
      "dial_code": "+504",
      "code": "HN",
      "locale": "es-HN"
    },
    {
      "name": "Hong Kong",
      "dial_code": "+852",
      "code": "HK",
      "locale": "en-HK"
    },
    {
      "name": "Hungary",
      "dial_code": "+36",
      "code": "HU",
      "locale": "hu-HU"
    },
    {
      "name": "Indonesia",
      "dial_code": "+62",
      "code": "ID",
      "locale": "id-ID"
    },
    {
      "name": "Iran, Islamic Republic of Persian Gulf",
      "dial_code": "+98",
      "code": "IR",
      "locale": "fa-IR"
    },
    {
      "name": "Iraq",
      "dial_code": "+964",
      "code": "IQ",
      "locale": "ar-IQ"
    },
    {
      "name": "Ireland",
      "dial_code": "+353",
      "code": "IE",
      "locale": "en-IE"
    },
    {
      "name": "Israel",
      "dial_code": "+972",
      "code": "IL",
      "locale": "he-IL"
    },
    {
      "name": "Italy",
      "dial_code": "+39",
      "code": "IT",
      "locale": "it-IT"
    },
    {
      "name": "Jamaica",
      "dial_code": "+1876",
      "code": "JM",
      "locale": "en-JM"
    },
    {
      "name": "Japan",
      "dial_code": "+81",
      "code": "JP",
      "locale": "ja-JP"
    },
    {
      "name": "Jordan",
      "dial_code": "+962",
      "code": "JO",
      "locale": "ar-JO"
    },
    {
      "name": "Kazakhstan",
      "dial_code": "+77",
      "code": "KZ",
      "locale": "kk-KZ"
    },
    {
      "name": "Kenya",
      "dial_code": "+254",
      "code": "KE",
      "locale": "en-KE"
    },
    {
      "name": "Kiribati",
      "dial_code": "+686",
      "code": "KI",
      "locale": "en-KI"
    },
    {
      "name": "Korea, Republic of South Korea",
      "dial_code": "+82",
      "code": "KR",
      "locale": "ko-KR"
    },
    {
      "name": "Kuwait",
      "dial_code": "+965",
      "code": "KW",
      "locale": "ar-KW"
    },
    {
      "name": "Kyrgyzstan",
      "dial_code": "+996",
      "code": "KG",
      "locale": "ky-KG"
    },
    {
      "name": "Lebanon",
      "dial_code": "+961",
      "code": "LB",
      "locale": "az-LB"
    },
    {
      "name": "Lesotho",
      "dial_code": "+266",
      "code": "LS",
      "locale": "en-LS"
    },
    {
      "name": "Libyan Arab Jamahiriya",
      "dial_code": "+218",
      "code": "LY",
      "locale": "az-LY"
    },
    {
      "name": "Lithuania",
      "dial_code": "+370",
      "code": "LT",
      "locale": "lt-LT"
    },
    {
      "name": "Luxembourg",
      "dial_code": "+352",
      "code": "LU",
      "locale": "de-LU"
    },
    {
      "name": "Macao",
      "dial_code": "+853",
      "code": "MO",
      "locale": "en-MO"
    },
    {
      "name": "Madagascar",
      "dial_code": "+261",
      "code": "MG",
      "locale": "mg-MG"
    },
    {
      "name": "Malaysia",
      "dial_code": "+60",
      "code": "MY",
      "locale": "ms-MY"
    },
    {
      "name": "Maldives",
      "dial_code": "+960",
      "code": "MV",
      "locale": "dv-MV"
    },
    {
      "name": "Malta",
      "dial_code": "+356",
      "code": "MT",
      "locale": "en-MT"
    },
    {
      "name": "Martinique",
      "dial_code": "+596",
      "code": "MQ",
      "locale": "fr-MQ"
    },
    {
      "name": "Mauritius",
      "dial_code": "+230",
      "code": "MU",
      "locale": "en-MU"
    },
    {
      "name": "Mexico",
      "dial_code": "+52",
      "code": "MX",
      "locale": "es-MX"
    },
    {
      "name": "Mongolia",
      "dial_code": "+976",
      "code": "MN",
      "locale": "mn-MN"
    },
    {
      "name": "Mozambique",
      "dial_code": "+258",
      "code": "MZ",
      "locale": "mz-MZ"
    },
    {
      "name": "Myanmar",
      "dial_code": "+95",
      "code": "MM",
      "locale": "my-MM"
    },
    {
      "name": "Nepal",
      "dial_code": "+977",
      "code": "NP",
      "locale": "ne-NP"
    },
    {
      "name": "Netherlands",
      "dial_code": "+31",
      "code": "NL",
      "locale": "nl-NL"
    },
    {
      "name": "New Zealand",
      "dial_code": "+64",
      "code": "NZ",
      "locale": "en-NZ"
    },
    {
      "name": "Nicaragua",
      "dial_code": "+505",
      "code": "NI",
      "locale": "es-NI"
    },
    {
      "name": "Nigeria",
      "dial_code": "+234",
      "code": "NG",
      "locale": "en-NG"
    },
    {
      "name": "Norway",
      "dial_code": "+47",
      "code": "NO",
      "locale": "nb-NO"
    },
    {
      "name": "Palestinian Territory, Occupied",
      "dial_code": "+970",
      "code": "PS",
      "locale": "ar-PS"
    },
    {
      "name": "Panama",
      "dial_code": "+507",
      "code": "PA",
      "locale": "es-PA"
    },
    {
      "name": "Papua New Guinea",
      "dial_code": "+675",
      "code": "PG",
      "locale": "en-PG"
    },
    {
      "name": "Paraguay",
      "dial_code": "+595",
      "code": "PY",
      "locale": "es-PY"
    },
    {
      "name": "Peru",
      "dial_code": "+51",
      "code": "PE",
      "locale": "es-PE"
    },
    {
      "name": "Philippines",
      "dial_code": "+63",
      "code": "PH",
      "locale": "en-PH"
    },
    {
      "name": "Poland",
      "dial_code": "+48",
      "code": "PL",
      "locale": "pl-PL"
    },
    {
      "name": "Portugal",
      "dial_code": "+351",
      "code": "PT",
      "locale": "pt-PT"
    },
    {
      "name": "Romania",
      "dial_code": "+40",
      "code": "RO",
      "locale": "ro-RO"
    },
    {
      "name": "Russia",
      "dial_code": "+7",
      "code": "RU",
      "locale": "ru-RU"
    },
    {
      "name": "Rwanda",
      "dial_code": "+250",
      "code": "RW",
      "locale": "en-RW"
    },
    {
      "name": "Reunion",
      "dial_code": "+262",
      "code": "RE",
      "locale": "fr-RE"
    },
    {
      "name": "Saint Kitts and Nevis",
      "dial_code": "+1869",
      "code": "KN",
      "locale": "en-KN"
    },
    {
      "name": "San Marino",
      "dial_code": "+378",
      "code": "SM",
      "locale": "it-SM"
    },
    {
      "name": "Saudi Arabia",
      "dial_code": "+966",
      "code": "SA",
      "locale": "ar-SA"
    },
    {
      "name": "Serbia",
      "dial_code": "+381",
      "code": "RS",
      "locale": "sr-RS"
    },
    {
      "name": "Sierra Leone",
      "dial_code": "+232",
      "code": "SL",
      "locale": "en-SL"
    },
    {
      "name": "Singapore",
      "dial_code": "+65",
      "code": "SG",
      "locale": "en-SG"
    },
    {
      "name": "Slovakia",
      "dial_code": "+421",
      "code": "SK",
      "locale": "sk-SK"
    },
    {
      "name": "Slovenia",
      "dial_code": "+386",
      "code": "SI",
      "locale": "sl-SI"
    },
    {
      "name": "Somalia",
      "dial_code": "+252",
      "code": "SO",
      "locale": "so-SO"
    },
    {
      "name": "South Africa",
      "dial_code": "+27",
      "code": "ZA",
      "locale": "en-ZA"
    },
    {
      "name": "South Sudan",
      "dial_code": "+211",
      "code": "SS",
      "locale": "en-SS"
    },
    {
      "name": "Spain",
      "dial_code": "+34",
      "code": "ES",
      "locale": "es-ES"
    },
    {
      "name": "Sri Lanka",
      "dial_code": "+94",
      "code": "LK",
      "locale": "si-LK"
    },
    {
      "name": "Sudan",
      "dial_code": "+249",
      "code": "SD",
      "locale": "ar-SD"
    },
    {
      "name": "Sweden",
      "dial_code": "+46",
      "code": "SE",
      "locale": "sv-SE"
    },
    {
      "name": "Switzerland",
      "dial_code": "+41",
      "code": "CH",
      "locale": "de-CH"
    },
    {
      "name": "Syrian Arab Republic",
      "dial_code": "+963",
      "code": "SY",
      "locale": "ar-SY"
    },
    {
      "name": "Taiwan",
      "dial_code": "+886",
      "code": "TW",
      "locale": "zh-TW"
    },
    {
      "name": "Tajikistan",
      "dial_code": "+992",
      "code": "TJ",
      "locale": "tg-TJ"
    },
    {
      "name": "Tanzania, United Republic of Tanzania",
      "dial_code": "+255",
      "code": "TZ",
      "locale": "en-TZ"
    },
    {
      "name": "Thailand",
      "dial_code": "+66",
      "code": "TH",
      "locale": "th-TH"
    },
    {
      "name": "Tunisia",
      "dial_code": "+216",
      "code": "TN",
      "locale": "ar-TN"
    },
    {
      "name": "Turkey",
      "dial_code": "+90",
      "code": "TR",
      "locale": "tr-TR"
    },
    {
      "name": "Turkmenistan",
      "dial_code": "+993",
      "code": "TM",
      "locale": "tk-TM"
    },
    {
      "name": "Uganda",
      "dial_code": "+256",
      "code": "UG",
      "locale": "en-UG"
    },
    {
      "name": "Ukraine",
      "dial_code": "+380",
      "code": "UA",
      "locale": "uk-UA"
    },
    {
      "name": "United Arab Emirates",
      "dial_code": "+971",
      "code": "AE",
      "locale": "ar-AE"
    },
    {
      "name": "United Kingdom",
      "dial_code": "+44",
      "code": "GB",
      "locale": "en-GB"
    },
    {
      "name": "Uruguay",
      "dial_code": "+598",
      "code": "UY",
      "locale": "es-UY"
    },
    {
      "name": "Uzbekistan",
      "dial_code": "+998",
      "code": "UZ",
      "locale": "uz-UZ"
    },
    {
      "name": "Venezuela, Bolivarian Republic of Venezuela",
      "dial_code": "+58",
      "code": "VE",
      "locale": "es-VE"
    },
    {
      "name": "Vietnam",
      "dial_code": "+84",
      "code": "VN",
      "locale": "vi-VN"
    },
    {
      "name": "Wallis and Futuna",
      "dial_code": "+681",
      "code": "WF",
      "locale": "fr-WF"
    },
    {
      "name": "Yemen",
      "dial_code": "+967",
      "code": "YE",
      "locale": "ar-YE"
    },
    {
      "name": "Zambia",
      "dial_code": "+260",
      "code": "ZM",
      "locale": "en-ZM"
    },
    {
      "name": "Zimbabwe",
      "dial_code": "+263",
      "code": "ZW",
      "locale": "en-ZW"
    }
]