import React, { useState } from "react";
import { contactUs } from '../../services'

const ContactForm = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address, setAddress] = useState('')
  const [comment, setComment] = useState('')
  const [errorLog, setErrorLog] = useState({})
  const [successMsg, setSuccessMsg] = useState(false)
  const [loader, setLoader] = useState(false)

  const handler = (e, type) => {
    setErrorLog({})
    setSuccessMsg(false)
    const { value } = e.target
    let errorLog_ = {}
    switch (type) {
      case 'first_name':
        setFirstName(value)
        if (value == '') {
          errorLog_ = { ...errorLog, first_name: ['First name is required.'] }
          setErrorLog(errorLog_)
        }
        break;
      case 'last_name':
        setLastName(value)
        if (value == '') {
          errorLog_ = { ...errorLog, last_name: ['Last name is required.'] }
          setErrorLog(errorLog_)
        }
        break;
      case 'address':
        setAddress(value)
        if (value == '') {
          errorLog_ = { ...errorLog, address: ['Address is required.'] }
          setErrorLog(errorLog_)
        }
        break;
      case 'comment':
        setComment(value)
        if (value == '') {
          errorLog_ = { ...errorLog, comment: ['Comment is required.'] }
          setErrorLog(errorLog_)
        }
        break;
      default:
        break;
    }
  }

  const contactHandler = async () => {
    setErrorLog({})
    setSuccessMsg(false)
    if (firstName === '' && lastName === '' && address === '' && comment === '') {
      let errorLog_ = {
        ...errorLog,
        first_name: ['First name is required'],
        last_name: ['Last name is required'],
        address: ['Address is required'],
        comment: ['Comment is required']
      }
      setErrorLog(errorLog_)
    } else if (firstName === '') {
      let errorLog_ = { ...errorLog, first_name: ['First name is required'] }
      setErrorLog(errorLog_)
    } else if (lastName === '') {
      let errorLog_ = { ...errorLog, last_name: ['Last name is required'] }
      setErrorLog(errorLog_)
    } else if (address === '') {
      let errorLog_ = { ...errorLog, address: ['Address is required'] }
      setErrorLog(errorLog_)
    } else if (comment === '') {
      let errorLog_ = { ...errorLog, comment: ['Comment is required'] }
      setErrorLog(errorLog_)
    } else {
      //console.log("")
    }

    if (firstName !== '' && lastName !== '' && address !== '' && comment !== '') {
      setLoader(true)
      try {
        let responseData = await contactUs({ first_name: firstName, last_name: lastName, address, comment })
        const { code, data } = responseData
        if (code === 200) {
          setLoader(false)
          setFirstName('')
          setLastName('')
          setAddress('')
          setComment('')
          setSuccessMsg(true)
        } else if (code === 400) {
          setErrorLog(data)
          setLoader(false)
          setSuccessMsg(false)
        }
      } catch (error) {
        setLoader(false)
        setSuccessMsg(false)
      }
      setLoader(false)
    }
  }


  return (
    <>
      <div className="contact-form mt-4">
        <div className="row">
          <div className="col-md-2">
            <div className="heading-form">
              <h4>Message Us Now</h4>
            </div>
          </div>
          <div className="col-md-10">
            <div className="contact-inputs">
              <div className="row">
                <div className="col-md-6">
                  <div>
                    <input type="text" value={firstName} onChange={(e) => { handler(e, 'first_name') }} className="form-control" id="inputEmail4"  placeholder='First Name*'/>
                    {errorLog.first_name &&
                      <div className="text-start text-danger">{errorLog.first_name}</div>
                    }
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <input type="text" value={lastName} onChange={(e) => { handler(e, 'last_name') }} className="form-control" id="inputPassword4" placeholder='Last Name*'/>
                    {errorLog.last_name &&
                      <div className="text-start text-danger">{errorLog.last_name}</div>
                    }
                  </div>
                </div>
                <div className="col-md-12">
                  <div>
                    <input type="text" value={address} onChange={(e) => { handler(e, 'address') }} className="form-control" id="inputAddress" placeholder='Address*'/>
                    {errorLog.address &&
                      <div className="text-start text-danger">{errorLog.address}</div>
                    }
                  </div>
                </div>
                <div className="col-md-12">
                  <div>
                    <input type="text" className="form-control" value={comment} onChange={(e) => { handler(e, 'comment') }} placeholder="Comments*"
                    id="floatingTextarea2"
                    ></input>
                    {errorLog.comment &&
                      <div className="text-start text-danger">{errorLog.comment}</div>
                    }
                  </div>
                </div>
              </div>
              <div className='text-center text-md-end contact-submit'>
                {successMsg &&
                  <div className="text-start text-success mb-2">Thankyou, We have received your message</div>
                }
                <button className="w-100" onClick={() => !loader ? contactHandler() : () => { }}>
                  {loader ?
                    <div className="spinner-border text-light" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    :
                    'Submit Now'
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default ContactForm;
