import "../src/assets/css/main.css";
import "../src/assets/css/general.css";
import Index from "./components/pages/index";
import Profile from "./components/pages/Profile";
import Notification from "./components/pages/Notification";
import Search from "./components/pages/Search";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ForgotPassword from "./components/pages/ForgotPassword";
import VerifyEmail from "./components/pages/VerifyEmail";
import CreatePassword from "./components/pages/CreatePassword";
import Login from "./components/pages/Login";
import Register from "./components/pages/Register";
import History from "./components/pages/History";
import Faqs from "./components/pages/Faqs";
import Contact from "./components/pages/Contact";
import TermsAndPrivacy from "./components/pages/TermsAndPrivacy";
import Terms_1 from "./components/sections-components/Terms_1";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute"
import { QueryProvider } from './CustomContext';

function App() {
  return (
    <>
      <BrowserRouter>
        <QueryProvider>
            <Routes>
              <Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
              <Route path="/verify-email/:token" element={<PublicRoute><VerifyEmail /></PublicRoute>} />
              <Route path="/create-password/:token" element={<PublicRoute><CreatePassword /></PublicRoute>} />
              <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
              <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />

              {/* Protected Routes */}
              
              <Route path="/" element={<ProtectedRoute><Index /></ProtectedRoute>} />
              <Route path="/home" element={<ProtectedRoute><Index /></ProtectedRoute>} />
              <Route path="/history" element={<ProtectedRoute><History /></ProtectedRoute>} />
              <Route path="/faqs" element={<ProtectedRoute><Faqs /></ProtectedRoute>} />
              <Route path="/terms-and-privacy/" element={<ProtectedRoute><TermsAndPrivacy /></ProtectedRoute>} />
              <Route path="/contact" element={<ProtectedRoute><Contact /></ProtectedRoute>} />
              <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
              <Route path="/notifications" element={<ProtectedRoute><Notification /></ProtectedRoute>} />
              <Route path="/search" element={<ProtectedRoute><Search /></ProtectedRoute>} />
              <Route path="*" element={<ProtectedRoute><Index /></ProtectedRoute>} />
            </Routes>
          </QueryProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
