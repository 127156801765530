import React from 'react'
import avatar from "../../assets/img/avatar2.png";

export default function CuNotification({ data }) {
    return (
        <div className="notification-table bg-white rounded-5 shadow-sm mt-5 table-responsive">
            <table className="table rounded-4">
                <thead>
                    <tr>
                        <td>Message Sender</td>
                        <td>Message</td>
                        <td>Status</td>
                        <td>Date</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, index) => {
                            let date = new Date(item.created_at)
                            let day = ("0" + date.getDate()).slice(-2);
                            let month = ("0" + (date.getMonth() + 1)).slice(-2);
                            let year = date.getFullYear();
                            let dateFormat = `${day}/${month}/${year}`;

                            let status = item.is_active ? "Active" : "Deactivate"
                            return (
                                <tr key={index} className="table-data cr-pointer">
                                    <td>
                                        <div className='message-sender d-flex'>
                                            <img src={avatar} width={66} height={66} className="rounded-3" />
                                            <div className="noti-info">
                                                <h5 className="">{item.sender.first_name} {item.sender.last_name}</h5>
                                                <p>{item.sender.email}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='noti-message'>
                                            <h5>{item.heading}</h5>
                                            <p>{item.message}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='noti-status'>
                                            <p className={"badge" + (item.is_active ? " bg-success" : " bg-danger")}>{status}</p>
                                        </div>
                                    </td>
                                    <td className='date'>
                                        {dateFormat}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
