export async function canvasPreview(image, canvas, crop,imageCanvasRef) {



  // mask generations
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, image.width, image.height);
  ctx.fillStyle = 'black';
  ctx.fillRect(0, 0, image.width, image.height);
  ctx.fillStyle = 'white';
  ctx.beginPath();
  const cropPercentage = {
    x: (crop.x / image.width) * 100,
    y: (crop.y / image.height) * 100,
    width: (crop.width / image.width) * 100,
    height: (crop.height / image.height) * 100
  };
  const x = (cropPercentage.x / 100) * canvas.width;
  const y = (cropPercentage.y / 100) * canvas.height;
  const width = (cropPercentage.width / 100) * canvas.width;
  const height = (cropPercentage.height / 100) * canvas.height;
  ctx.fillRect(x, y, width, height);
  ctx.stroke();
  ctx.closePath();
  ctx.fill();


  // image canva
  const imageCtx = imageCanvasRef.getContext('2d')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  const pixelRatio = window.devicePixelRatio
  imageCanvasRef.width = Math.floor(crop.width * scaleX * pixelRatio)
  imageCanvasRef.height = Math.floor(crop.height * scaleY * pixelRatio)
  imageCtx.scale(pixelRatio, pixelRatio)
  imageCtx.imageSmoothingQuality = 'high'
  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY
  const centerX = image.naturalWidth / 2
  const centerY = image.naturalHeight / 2
  imageCtx.save()
  imageCtx.translate(-cropX, -cropY)
  imageCtx.translate(centerX, centerY)
  imageCtx.translate(-centerX, -centerY)
  imageCtx.drawImage(image,0,0,image.naturalWidth,image.naturalHeight,0,0,image.naturalWidth,image.naturalHeight,)
  imageCtx.restore()  

  return canvas.toDataURL()
}
