import React, { useEffect, useState, useCallback } from "react";
import Sidebar from "../../global-components/Sidebar";
import Header from "../../global-components/Header";
import { Link } from "react-router-dom";
import GetAlternative from "./GetAlternative";
import { generateProduct_ } from '../../../services'
import ImageCard from "./ImageCard";
import SubmitScreen from "./SubmitScreen";

const Result = ({ imageHW, data, bbox, backHandler_, processId }) => {
    const [imageData, _] = useState(data)
    const [showStep5, setShowStep5] = useState(false)
    const [filename, setFilename] = useState('')

    // useEffect(() => {
    //     let url_ = data.src
    //     const parts = url_.split('/');
    //     setFilename(parts[parts.length - 1])
    //     imageUrlToBase64(data.src).then((blob) => { setImageData(blob) })
    // }, [data])

    const imageUrlToBase64 = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            };
            reader.onerror = reject;
        });
    };

    const backToStepHandler = () => {
        backHandler_()
    }

    const submitHandler = () => {
        setShowStep5(true);
    }

    const [selectedImage, setSelectedImage] = useState({})
    const [showProductComp, setshowProductComp] = useState(false)
    const [productData, setProductData] = useState([])
    const [errorLog, setErrorLog] = useState('')
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);

    const imageSelectHandler = (data_) => {
        setErrorLog('')
        setSelectedImage(data_)
    }

    const backHandler = () => {
        backHandler_()
    }

    const generateProduct = async (selectedData, cropedData) => {
        let formData = new FormData();
        formData.append('image', selectedData.blob, selectedData.fileName);
        formData.append('process_id', processId);
        formData.append('croped', cropedData.blob, cropedData.filename);
        let responseData = await generateProduct_(formData)
        const { code, data } = responseData
        if (code == 200) {
            if (data.hasOwnProperty("body")) {
                let productData = data.body.data
                if (productData.length > 0) {
                    setProductData(productData)
                    setshowProductComp(true)
                } else {
                    setErrorLog('Product not found.')
                }
            } else {
                setErrorLog('Something went wrong please try again later')
            }
        } else {
            setErrorLog('Something went wrong please try again later')
        }
    }

    const bboxHandler = (cropedData_) => {
        imageUrlToBase64(selectedImage.src).then((data) => {
            if (data) {
                generateProduct(data, cropedData_)
            }

        })
    }


    return (
        <>
            {!showStep5 ?
                <div className="main-div">
                    <div className="container-fluid pt-5">
                        <div className="row">
                            <Sidebar />
                            <div className="main-content col-lg-9">
                                <div className="container">
                                    <Header title={'Hasham Naeem'} />
                                    <hr className='hr w-100' />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="">
                                                <div className="">
                                                    <h4>The Result</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 ">
                                                        <img className='rounded-4' width={'100%'} src={imageData} alt="" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="w-100 flex-wrap d-flex justify-content-center">
                                                        {
                                                            imageData.map((item, index) => {
                                                                if (index === 0) {
                                                                    return (
                                                                        <div className="col-md-12 ">
                                                                            <img className='rounded-4' width={'100%'} src={item.src} alt="" />
                                                                        </div>
                                                                    );
                                                                } else {
                                                                    return null; // This ensures that only the first item is rendered
                                                                }
                                                            })
                                                        }
                                                        <div></div>
                                                    </div>
                                                    {errorLog &&
                                                        <div className="text-center text-danger">{errorLog}</div>
                                                    }
                                                    {/* {Object.keys(selectedImage).length > 0 &&
                                                        <BoundBoxCroper
                                                            data={selectedImage}
                                                            bbox={bbox}
                                                            bboxHandler_={(data) => { bboxHandler(data) }}
                                                            imageHW={imageHW}
                                                        />
                                                    } */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-xs-4 col-md-6">
                                            <div onClick={() => backHandler()} className="buy-now text-center text-md-end mr-3">
                                                <Link>
                                                    <button>Back To Previous</button>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-xs-4 col-md-6">
                                            <div onClick={() => submitHandler()} type="button" className="text-center text-md-start download">
                                                <Link>
                                                    <button>Submit or Get Suggestions</button>
                                                </Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <SubmitScreen backHandler_={backHandler_} processId={processId} picture={imageData} />
            }
        </>
    )
};

export default Result;
