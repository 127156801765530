import React, { useEffect, useState } from "react";
import Sidebar from "../global-components/Sidebar";
import Header from "../global-components/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useQuery } from '../../CustomContext';
import { useSearchParams } from "react-router-dom";
import ProjectHistory from "../global-components/ProjectHistory";
import CuNotification from '../../components/global-components/CuNotification'

const Search = () => {
    const { searchQuery } = useQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    let searchQuery_ = searchParams.get("query")
    const [enableHeight, setEnableHeight] = useState(true)
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (searchQuery) {
            let data_ = searchQuery.data
            if (data_) {
                console.log(data_)
                if ((data_.hasOwnProperty('history') === true && data_.history.length > 0) || (data_.hasOwnProperty('notification') === true && data_.notification.length > 0)) {
                    console.log("1")
                    setEnableHeight(false)
                } else {
                    console.log("2")
                    setEnableHeight(true)
                }
            } else {
                setEnableHeight(true)
                console.log("3")
            }
        }
    }, [])

    console.log(enableHeight, "enableHeight")

    return (
        <div className="main-div">
            <div className="container-fluid pt-5">
                <div className="row ">
                    <Sidebar />
                    <div className="main-content container col-lg-9">
                        <div className="container">
                            <Header
                                title="Search"
                                subtitle="Upload image Or generate new Home-Interior with AI"
                            />
                            <hr className='hr w-100' />
                            <div className="">
                                <div className="search-page">
                                    {searchQuery &&
                                        searchQuery?.data ?
                                        (Object.keys(searchQuery.data).length > 0 && (searchQuery.data.history?.length > 0 || searchQuery.data.notification?.length > 0)) ?
                                            <>
                                                <>
                                                    {
                                                        searchQuery.data.history.length > 0 ?
                                                            <div className="row mt-1">
                                                                <div className="">
                                                                    <h5 className="p-0 m-0">Recent projects</h5>
                                                                    <h6 className="text-secondary pt-1 m-0">
                                                                        Architect design houses
                                                                    </h6>
                                                                </div>
                                                                <ProjectHistory ishow={false} type={"all"} isSearch={true} searchData={searchQuery.data.history} />
                                                            </div>
                                                            : <></>
                                                    }
                                                </>
                                                <>
                                                    {
                                                        searchQuery.data.notification.length > 0
                                                            ?
                                                            <CuNotification data={searchQuery.data.notification} />
                                                            : <></>
                                                    }
                                                </>
                                            </>
                                            : <div className="search_not_found"><h5>Not Found</h5></div>
                                        : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Search;
