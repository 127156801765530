import React, { useState } from "react";
import ForgotLogo from "../../assets/img/forgot-logo.png";
import Logo from "../../assets/img/logo-png.png";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from '../../services'
import { checkValidEmail } from '../../GlobalFunction'
import Email from '../../assets/img/Email.png'
import Component4 from '../../assets/img/component4.png'
import LeftArrow from '../../assets/img/leftarrow.svg'

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [errorLog, setErrorLog] = useState({})
  const [loader, setLoader] = useState(false)

  const handler = (e, type) => {
    delete errorLog['non_field_errors']
    setErrorLog(errorLog)
    setErrorLog({})
    const { value } = e.target
    let errorLog_ = {}
    switch (type) {
      case 'email':
        setEmail(value)
        let emailError = checkValidEmail(value)
        errorLog_ = { ...errorLog, email: [emailError] }
        setErrorLog(errorLog_)
        break;
      default:
        break;
    }
  }

  const forgotPasswordHandler = async () => {
    setErrorLog({})
    if (email === '') {
      let errorLog_ = { ...errorLog, email: ['Email address is required'] }
      setErrorLog(errorLog_)
    }
    if (email !== '') {
      setLoader(true)
      let responseData = await forgotPassword({ email })
      const { code, data } = responseData
      if (code === 200) {
        const { token, email } = data
        navigate(`/verify-email/${token}?email=${email}`)

      } else if (code === 400) {
        setErrorLog(data)
      }
      setLoader(false)
    }
  }

  return (
    <div className="forgot-password-steps d-flex align-items-center justify-content-center">
      <div className="container-fluid row px-0">
        <div className="col-md-8 col-sm-12 p2container1  d-flex align-items-center justify-content-center">
          <div className="p2container2">
            <img className="p2container3" src={Component4} alt="" />
            <h4 className='p2text2'>Forgot your Password? <br />
              NO worries!</h4>
          </div>
        </div>

        <div className="col-md-4 col-sm-12 Reg-right-main">
          <div className="Reg-right d-flex align-items-center justify-content-center">
            <div className="Form_reg_div">
              <h4 className='text-center'>Forgot Password?</h4>
              <p className='text-center'>No worries, we'll send you reset information</p>

              <div className="Reg_input_div mt-2">
                <div className='d-flex align-items-center justify-content-center'>
                  <input className='Reg_form_input' onChange={(e)=>{handler(e,'email')}} type="Email" placeholder='Email Address' />
                  <img className='Reg_form_input_icon' src={Email} alt="" />
                </div>
                
              </div>
              {errorLog.email &&
                <div className="text-start text-danger">{errorLog.email}</div>
              }
              {errorLog.non_field_errors &&
                <div className="text-start text-danger">{errorLog.non_field_errors}</div>
              }
              
              <div className="Reg_input_div mt-2">
                <div className='d-flex align-items-center justify-content-center'>
                <button className='Reg_form_input_btn d-flex  align-items-center justify-content-center mt-3' onClick={() => { forgotPasswordHandler() }}>
                {loader ?
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  :
                  'Send Now'
                }
              </button>
                </div>
              </div>
              
              <div className="text-center mt-3">
                <Link to={'/login'}>
                <button>
                  <img className="px-2" src={LeftArrow} alt="" />
                  Back to Login 
                </button>
                </Link>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
