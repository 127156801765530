import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/logo.png";
import { Link, useLocation } from "react-router-dom";

const ResponsiveSidebar = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    // Set the active tab based on the current path
    setActiveTab(getActiveTab(location.pathname));
  }, [location.pathname]);

  const handleLinkClick = (tabName) => {
    setActiveTab(tabName);
  };

  // Utility function to get the active tab name based on the path
  const getActiveTab = (pathname) => {
    if (pathname === "/home") {
      return "Home";
    } else if (pathname === "/profile") {
      return "Profile";
    } else if (pathname === "/notifications") {
      return "Notifications";
    } else if (pathname === "/history") {
      return "History";
    } else if (pathname === "/faqs") {
      return "Faqs";
    } else if (pathname === "/contact") {
      return "Contact";
    } else if (pathname === "/terms-and-privacy") {
      return "Terms";
    } else {
      return "";
    }
  };

  const goToHomeHandler = () =>{
    window.location.reload()
  }

  return (
    <>
      {/* responsive Sidebar */}
      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            Navigation
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body p-0 m-0">
          <nav
            className="p-4 m-0 d-lg-block sidebar border-end"
            style={{ height: "100vh" }}
          >
            <div className="position-sticky">
              <div className="bg-white d-flex border-bottom pb-3 align-items-center justify-content-start">
                <div onClick={()=>{goToHomeHandler()}} className="p-0 m-0 pointer_cursor ">
                  <img src={Logo} width={120} />
                </div>
              </div>
              <ul className="nav flex-column mt-3 pt-3">
                <li className="nav-item">
                  <div
                    onClick={()=>{goToHomeHandler()}}
                    className={`nav-link text-light-1 pd-10 text-dark d-flex align-items-center ${
                      activeTab === "Home" ? "active-tab" : ""
                    }`}
                    aria-current="page"
                  >
                    <i className="material-icons p-1 me-2 rounded-3 text-primary-dark">
                      home
                    </i>
                    Home
                  </div>
                </li>

                <li className="nav-item">
                  <Link
                    to={"/profile"}
                    onClick={() => handleLinkClick("Profile")}
                    className={`text-light-1 pd-10 nav-link text-dark my-1 d-flex align-items-center ${
                      activeTab === "Profile" ? "active-tab" : ""
                    }`}
                  >
                    <i className="material-icons p-1 me-2 rounded-3 text-primary-dark">
                      person
                    </i>
                    Profile
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/notifications"}
                    onClick={() => handleLinkClick("Notifications")}
                    className={`text-light-1 nav-link text-dark my-1 pd-10 d-flex align-items-center ${
                      activeTab === "Notifications" ? "active-tab" : ""
                    }`}
                  >
                    <i className="material-icons p-1 me-2 rounded-3 text-primary-dark">
                      notifications
                    </i>
                    Notifications
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/history"}
                    onClick={() => handleLinkClick("History")}
                    className={`text-light-1 nav-link text-dark my-1 pd-10 d-flex align-items-center ${
                      activeTab === "History" ? "active-tab" : ""
                    }`}
                  >
                    <i className="material-icons p-1 me-2 rounded-3 text-primary-dark">
                      history
                    </i>
                    History
                  </Link>
                </li>
                {/* <li className="nav-item mt-3 mb-2">
            <h6>Support</h6>
          </li> */}
                <li className="nav-item">
                  <Link
                    to={"/faqs"}
                    onClick={() => handleLinkClick("Faqs")}
                    className={`text-light-1 nav-link text-dark my-1 pd-10 d-flex align-items-center ${
                      activeTab === "Faqs" ? "active-tab" : ""
                    }`}
                  >
                    <i className="material-icons p-1 me-2 rounded-3 text-primary-dark">
                      error
                    </i>
                    Faq's
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/contact"}
                    onClick={() => handleLinkClick("Contact")}
                    className={`text-light-1 nav-link text-dark my-1 pd-10 d-flex align-items-center ${
                      activeTab === "Contact" ? "active-tab" : ""
                    }`}
                  >
                    <i className="material-icons p-1 me-2 rounded-3 text-primary-dark">
                      call
                    </i>
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/terms-and-privacy"}
                    onClick={() => handleLinkClick("Terms")}
                    className={`text-light-1 nav-link text-dark my-1 pd-10 d-flex align-items-center ${
                      activeTab === "Terms" ? "active-tab" : ""
                    }`}
                  >
                    <i className="material-icons p-1 me-2 rounded-3 text-primary-dark">
                      error
                    </i>
                    Terms & Privacy
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default ResponsiveSidebar;
